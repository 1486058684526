.clients-block {
  padding-top: 20px;
  padding-bottom: 10px;
  color: white;
  background-image: url(../img/bg/clients_bg.jpg);
  background-size: cover;
  background-position: center;
  @media (min-width: 768px) {
    padding-top: 100px;
    padding-bottom: 70px;
  }
  .description {
    margin-bottom: 20px;
    font-size: 10px;
    font-weight: 300;
    @media (min-width: 768px) {
      margin-bottom: 60px;
      font-size: 18px;
    }
  }
}

.clients-page {
  padding-top: 40px;
  @media (min-width: 768px) {
    padding-top: 20px;
  }
}

.clients-logo-slider {
  margin: -15px;
  .slide {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 30px;
  }
  .img-wrap {
    display: block!important;
    transition: transform .25s;
    &:hover {
      transform: scale(1.1);
    }
  }
  .slick-list {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .clients-dots {
    position: absolute;

    left: 15px;
    width: calc(~"100vw - 30px");
    display: flex;
    bottom: 10px;
    @media (min-width: 768px) {
      bottom: 0;
    }
    li {
      width: 70px;

      padding-top: 3px;
      transition: all 1s;

      &:not(:last-of-type) {
        margin-right: 10px;
        @media (min-width: 768px) {
          margin-right: 20px;
        }

      }
      button {
        display: block;
        width: 100%;
        height: 2px;
        opacity: .25;
        background: white;
        transition: all 1s;
      }
    }
    .slick-active {
      padding-top: 0;
      button {
        opacity: 1;
        height: 5px;

      }
    }
  }
}


.clients-row {
  .flex-item {
    width: calc(~"100% / 2");
    @media (min-width: 768px) {
      width: calc(~"100% / 3");
    }

    @media (min-width: 992px) {
      width: calc(~"100% / 4");
    }

    @media (min-width: 1280px) {
      width: calc(~"100% / 5");
    }
    @media (min-width: 1920px) {
      width: calc(~"100% / 7");
      &.hidden-lg {
        display: block!important;
      }
    }

  }
  .all-clients-tile {
    display: block;
    padding-bottom: 100%;
    position: relative;
    transition: all .25s;
    background: @red;
    color: white;
    .link-colored(white, none);
    &::after {
      display: block;
      content: "";
      position: absolute;
      top: 10px;
      left: 10px;
      bottom: 10px;
      right: 10px;
      border: 4px solid white;
      @media (min-width: 768px) {
        top: 32px;
        left: 32px;
        bottom: 32px;
        right: 32px;
        border: 6px solid white;
      }
    }
    .text {
      position: absolute;
      text-transform: uppercase;
      top: 50%;
      left: 25px;
      right: 25px;
      transform: translateY(-50%);
      font-size: 14px;
      @media (min-width: 768px) {
        font-size: 25px;
        left: 52px;
        right: 52px;
      }
    }
    &:hover {
      transform: scale(1.1);
      box-shadow: 0 35px 28px #0e1115;
    }

  }
  .client-logo-tile {
    display: block;
    padding-bottom: 100%;
    position: relative;
    transition: all .25s;
    &::after {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 10px solid fade(white, 15%);
      @media (min-width: 768px) {
        border: 16px solid fade(white, 15%);
      }
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .front {
      background: white;
      opacity: 0;
      transition: opacity .25s;
    }
    &:hover {
      transform: scale(1.1);
      box-shadow: 0 35px 28px #0e1115;
      .front {
        opacity: 1;
      }
    }
  }
}



