.header {
  .contact-col-wrap  {
    width: 100%;
    text-align: right;
    > * {
      display: inline-block;
    }
    @media (min-width: 1280px) {
      //max-width: 50%!important;
      > * {
        display: block;
      }
    }
  }
  .contact-col-wrap {
    @media (min-width: 1280px) {
     /* max-width: 210px!important;*/
    }

  }
  .contact-col {
    .link-colored(@black_text, none);
    max-width: 180px;
    > * {
      float: right;
    }
    .phone {
      font-size: 24px;
      font-weight: bold;

    }
    .email {

      .footer-link {
        font-size: 15px;
      }

    }
  }
  .container {
    position: relative;
  }
  padding-top: 12px;
  padding-bottom: 12px;
  @media (min-width: 768px) {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  @media (min-width: 992px) {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .logo {
    //max-width: 310px;

    @media (min-width: 480px) {
      //max-width: 110px;

    }
    @media (min-width: 768px) {
      //max-width: 330px;

    }

  }

  .away-link-wrap {
    position: absolute;
    right: 0;
    padding-right: 0!important;
    top: 100%;
    margin-top: 12px;
    @media (min-width: 768px) {
      margin-top: 25px;
      right: 0;
      padding-right: 15px!important;
    }
    @media (min-width: 1920px) {
      margin-top: 0;
    }
    @media (max-width: 1919px) {
      .away-btn {
        border-color: #6a6a6a;
        background: #6a6a6a;
        .text {
          color: white;
        }
        .arrow {
          background: white;
          &::before {
            border-color: @red;
          }
        }
      }
    }

    @media (min-width: 1920px) {
      position: static;
    }
  }

}

.header-sticky {
  top: 0;
  background-color: white;
  z-index: 500;
}

.main-menu {
  position: relative;
  .menu-btn {
    display: block;

    @media (min-width: 1280px) {
      display: none;
    }
  }
  .link-colored(@black_text, none);
  a {
    border-bottom: 1px solid transparent;
    display: inline-block;
    text-transform: uppercase;
    @media (min-width: 992px) {
      display: block;
    }

    //&:hover {
    //  border-color: currentColor;
    //}

    @media (min-width: 992px) {
      font-size: 16px;
    }

  }
  li {
    padding: 16px 0;
    display: block;
    list-style: none;
    margin-left: 14px;
    margin-right: 14px;
    position: relative;
    @media (min-width: 768px) {
      display: inline-block;
      //padding-left: 10px;
      //padding-right: 10px;
      //margin-left: 0;
      //margin-right: 0;
    }
    @media (min-width: 1280px) {
      margin-left: 10px;
      margin-right: 10px;

    }
    @media (min-width: 1920px) {
      margin-left: 12px;
      margin-right: 12px;

    }
    &.active,
    &:hover {
      &:after {
        display: block;
        content: "";
        position: absolute;
        top: 100%;
        margin-top: -10px;
        left: 0;
        right: 0;
        border-bottom: 10px solid @red;
        @media (min-width: 1280px) {
          margin-top: 16px;
        }
      }
    }
  }
  .menu-wrap {
    box-sizing: border-box;
    text-align: center;
    ul {
      margin: 0;
      padding: 0;
    }
    position: absolute;
    z-index: 10;
    width: 100vw;
    top: 100%;
    right: 0;
    background-color: #979899;
    box-shadow: none;
    transition: transform .25s, box-shadow .25s;
    //padding: 80px 30px 30px 30px;
    margin-top: 12px;
    margin-right: -15px;
    @media (min-width: 768px) {
      margin-top: 27px;
      margin-right: calc(~"360px - 50vw");
    }
    @media (min-width: 992px) {
      margin-right: calc(~"470px - 50vw");
    }

    display: none;

    @media (min-width: 1280px) {
      position: static;
      display: block;
      width: initial;
      background: none;
      box-shadow: none;
      padding: 0;
      transition: none;
      margin-right: 0;
      margin-top: 0;
    }
  }

  &.open {
    .menu-wrap {
      display: block;
    }
  }

}

.away-list {
  a {
    filter: grayscale(100%);
    opacity: .5;
    display: block;
    transition: filter .25s, opacity .25s;

    &:hover,
    &.hover {
      filter: grayscale(0);
      opacity: 1;
    }
  }
}

.away-list-wrap {
  display: none;
  @media (min-width: 1280px) {
    display: block;
  }
}

.desktop .header .phone {
  pointer-events: none;
}
