.svg-map {
  margin: -24% -25% -26%;
  @media (min-width: 1280px) {
    margin-bottom:  -16%;
  }
  @media (min-width: 1600px) {
    margin-bottom:  -15%;
  }
  @media (min-width: 1920px) {
    margin-bottom:  -18%;
  }
  position: relative;
  path {
    fill: #a7a9ac;
    stroke: #000000;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
    cursor: pointer;
    transition: fill .25s;
    &:hover,
    &.active {
      fill: @red;
    }
  }
}

.accordion-wrap {



}

.accordion {
  position: relative;
  z-index: 1;
  div {
    //color: @red;
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 5px;
    padding-left: 55px;
    @media (min-width: 992px) {
      padding-left: 55px;
      font-size: 14px;
    }
    @media (min-width: 1280px) {
      padding-left: 75px;
      font-size: 16px;
    }
    @media (min-width: 1920px) {
      padding-left: 95px;
    }
  }
  h3 {
    white-space: nowrap;
    font-size: 14px;
    font-weight: 300;
    margin: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
    @media (min-width: 768px) {
      font-size: 15px;
    }
    @media (min-width: 1280px) {
      font-size: 18px;
    }
    @media (min-width: 1920px) {
      font-size: 20px;
    }
    &::before {
      display: inline-block;
      vertical-align: middle;
      content: "";
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: #dcdcdc;
      margin-right: 15px;
      transition: background .25s;
    }
    span {

    }

    span {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      padding-right: 25px;
      white-space: normal;
      width: calc(~"100% - 50px");
      @media (min-width: 768px) {
        width: initial;
        max-width: calc(~"100% - 50px");
      }
      &::after {
        display: block;
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -7px;
        width: 11px;
        height: 11px;
        border-bottom: 1px solid white;
        border-right: 1px solid white;
        transform: rotate(45deg);
        transition: all .25s;
      }
    }
    &.active {
      font-weight: bold;
      &::before {
        background: @red;
      }
      span {
        &::after {
          transform: scale(1, -1) rotate(45deg) ;
          margin-top: -1px;
        }
      }
    }
  }
  h3 + div {
    display: none;
  }
  div:first-of-type {
    display: block;
  }
}


.geography-page {
  overflow: hidden;
  .slogan {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding-left: 30px;
    @media (min-width: 1280px) {
      margin-right: calc(~"635px - 50vw");

    }
    @media (min-width: 1600px) {
      margin-right: calc(~"635px - 50vw");
      padding-left: 60px;
    }
    @media (min-width: 1920px) {
      padding-left: 130px;
      margin-right: calc(~"925px - 50vw");
    }
    .flex-row {
      font-size: 36px;
      line-height: 1.2;
      background: #282c34;
      padding: 1em 1.5em;
      align-items: center;
      @media (min-width: 1280px) {
        font-size: 24px;
      }
      @media (min-width: 1600px) {
        font-size: 26px;
      }
      @media (min-width: 1920px) {
        font-size: 36px;
      }
      .icon {
        font-size: 1.666666666666667em;
      }
    }
    .text {
      margin-left: 30px;
      border-left: 1px solid fade(white, 50%);
      padding-left: 60px;
      @media (min-width: 1280px) {
        max-width: 500px;
      }
      @media (min-width: 1600px) {
        max-width: 500px;
      }
      @media (min-width: 1920px) {
        max-width: 700px;
      }
    }
  }
}
