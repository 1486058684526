.aside {
  .fast-links-wrap,
  .information-wrap {
    box-sizing: border-box;
    height: 100%;
    margin-bottom: 0;

  }
  .fast-links-wrap {
    padding: 80px 0 30px;
    background-color: @aside_bg;
    @media (min-width: 992px) {
      padding: 20px 0 10px;
    }
    .title {
      text-transform: uppercase;
      font-size: 30px;
      font-weight: bold;
      color: @red;
      position: absolute;
      top: 30px;
      left: 30px;
      padding-right: 60px;
    }
  }
  @media (max-width: 991px) {
    .information-content-wrap {
      overflow-y: auto;
      height: 100%;
    }

    .information-wrap {
      transition: transform .25s, box-shadow .25s;
      padding: 30px;
      background: white;
      .section-wrap {
        position: relative;
      }
      .underlined-title {
        position: sticky;
        background: white;
        top: 0;
        z-index: 1;
      }
    }

  }
  &.open {
    @media (max-width: 991px) {
      .fast-links-wrap,
      .information-wrap {
        transform: translateX(-100%);
        box-shadow: -15px 0 37px fade(black, 25%);
      }
    }
  }
}
