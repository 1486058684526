.btn {
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  vertical-align: top;
  text-decoration: none;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 1;
  }
}

.transparent-btn {
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  white-space: nowrap;

  .dotted {
    font-size: 16px;
    display: inline-block;
    position: relative;
    white-space: nowrap;
    &::after {
      display: block;
      content: '...........................................................................................................';
      position: absolute;
      width: 100%;
      overflow: hidden;
      left: 0;
      bottom: -.45em;
      transition: color .25s;
    }
  }
  &:hover {
    text-decoration: none;
    .dotted {
      &::after {
        color: transparent;
      }
    }
  }
}

.common-btn {
  display: inline-block;
  padding: 7px 10px;
  //transition: all .25s;
  position: relative;
  background-color: @disable;
  border: 1px solid @disable;
  white-space: nowrap;
  transition: box-shadow .25s;
  line-height: 1;
  text-decoration: none;
  @media (min-width: 768px) {
    padding: 14px 20px;
  }
  .text {
    text-align: center;
    font-size: 16px;
    display: block;
    line-height: 1.4;
    pointer-events: none;
    vertical-align: middle;
    white-space: normal;
    color: white;
    text-transform: uppercase;

  }
  strong {
    font-weight: bold;
  }

  &:hover {
    text-decoration: none;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
    //box-shadow: 0 0 0 0 transparent;
  }
  &:focus {
    text-decoration: none;
    box-shadow: 0 0 0 0 transparent;
  }
  &:active {
    box-shadow: 0 0 0 0 transparent;
    top: 1px;
  }
}

.btn-block {
  display: block;
}

.menu-btn {

  background: #3d4045;
  padding: 6px;
  line-height: 3px;
  .icon {
    font-size: 30px;
    color: white;
    @media (min-width: 768px) {
      font-size: 38px;
    }
  }

}

.close-btn,
.mfp-close-btn-in .mfp-close {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 44px;
  height: 44px;
  line-height: 44px;
  font-style: normal;
  font-size: 28px;
  border-radius: 50%;
  font-family: Arial, Baskerville, monospace;
  color: @red;
  background-color: fade(white, 75%);
  opacity: 1;
  z-index: 10;
  &:hover {
    background-color: white;
  }
}

.mfp-close-btn-in .mfp-close {
  top: 0;
  right: 0;
  @media (min-width: 768px) {
    top: 15px;
    right: 15px;
  }
}

.away-btn {
  background: white;
  border: 4px solid #e5e7ea;
  white-space: nowrap;
  padding: 4px;
  @media (min-width: 768px) {
    padding: 7px;

  }
  .arrow {
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    @media (min-width: 768px) {
      width: 33px;
      height: 33px;
    }
    position: relative;
    background: @red;
    margin-right: 10px;
    @media (min-width: 768px) {
      margin-right: 20px;
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -1px;
      transform: translate(-50%, -50%) rotate(-45deg);
      width: 5px;
      height: 5px;
      @media (min-width: 768px) {
        width: 10px;
        height: 10px;
        margin-left: -2px;
      }
      border-bottom: 1px solid white;
      border-right: 1px solid white;
    }
  }
  .text {
    display: inline-block;
    vertical-align: -1px;
    color: @red;
    font-size: 9px;
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 6px;
    @media (min-width: 768px) {
      margin-right: 13px;
      font-size: 16px;
      vertical-align: -3px;
    }
  }
}

.red-arrow-btn {
  background: @red;
  border: 4px solid @red;
  white-space: nowrap;
  padding: 4px;
  @media (min-width: 768px) {
    padding: 7px;
  }
  .arrow {
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    @media (min-width: 768px) {
      width: 33px;
      height: 33px;
    }
    position: relative;
    background: #282c34;
    margin-right: 10px;
    @media (min-width: 768px) {
      margin-right: 20px;
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -1px;
      transform: translate(-50%, -50%) rotate(-45deg);
      width: 5px;
      height: 5px;
      @media (min-width: 768px) {
        width: 10px;
        height: 10px;
        margin-left: -2px;
      }
      border-bottom: 1px solid white;
      border-right: 1px solid white;
    }
  }
  .text {
    display: inline-block;
    vertical-align: -1px;
    color: white;
    font-size: 9px;
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 6px;
    @media (min-width: 768px) {
      margin-right: 13px;
      font-size: 16px;
      vertical-align: -3px;
    }
  }
}

.white-arrow-btn {
  background: transparent;
  border: 2px solid white;
  white-space: nowrap;
  padding: 6px;
  @media (min-width: 768px) {
    padding: 7px;
    border: 4px solid white;
  }
  .arrow {
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    @media (min-width: 768px) {
      width: 33px;
      height: 33px;
    }
    position: relative;
    background: white;
    margin-right: 10px;
    @media (min-width: 768px) {
      margin-right: 20px;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -1px;
      transform: translate(-50%, -50%) rotate(-45deg);
      width: 5px;
      height: 5px;
      @media (min-width: 768px) {
        width: 10px;
        height: 10px;
        margin-left: -2px;
      }
      border-bottom: 1px solid black;
      border-right: 1px solid black;
    }
  }
  .text {
    display: inline-block;
    vertical-align: -1px;
    color: white;
    font-size: 9px;
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 6px;
    @media (min-width: 768px) {
      margin-right: 13px;
      font-size: 16px;
      vertical-align: -3px;
    }
  }
}

.white-more-btn {
  background: transparent;
  border: 1px solid white;
  padding: 9px 17px 7px;
  @media (min-width: 768px) {
    padding: 16px 34px;
    border: 2px solid white;
  }
  .text {
    color: white;
    font-size: 9px;
    @media (min-width: 768px) {
      font-size: 16px;
    }
    font-weight: bold;
  }
}

.red-btn {
  background: @red;
  border: 4px solid @red;
  @media (min-width: 768px) {
    padding-left: 36px;
    padding-right: 36px;
  }
  .text {
    font-weight: 900;
    font-size: 16px;
  }
}

.more-btn {
  background: transparent;
  border: 2px solid @red;
  .text {
    text-transform: uppercase;
    color: @red;
    font-size: 16px;
    font-weight: bold;
  }
  @media (min-width: 768px) {
    padding: 20px 55px;
  }
}

.wide-btn {
  padding: 7px 20px;
  @media (min-width: 768px) {
    padding: 14px 60px;
  }
}

.service-more-btn {
  border-color: white;
  background: #d80927;
  border-width: 2px;
  @media (min-width: 768px) {
    border-width: 4px;
  }
  .text {
    color: white;
    font-weight: bold;
  }
}

.btn-with-number {
  white-space: nowrap;
  padding: 5px 10px;
  //background: #817e7e;
  @media (min-width: 768px) {
    padding: 10px 30px;
  }
  .text {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    font-weight: bold;
    font-size: 14px;
    color: white;
  }
  .btn-number {
    display: inline-block;
    vertical-align: middle;
    background: #171717;
    color: white;
    width: 33px;
    height: 33px;
    text-align: center;
    line-height: 33px;
    font-weight: 900;
    font-size: 12px;
    border-radius: 50%;
  }
}

.more-btn-wrap {
  position: relative;
  &::before {
    display: block;
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    right: 0;
    border-bottom: 1px solid @red;
  }

  .common-btn {
    position: relative;
    &::before {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 100%;
      margin-right: 4px;
      width: 30px;
      background: white;
    }
    &::after {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 100%;
      margin-left: 4px;
      width: 30px;
      background: white;
    }
  }
}
