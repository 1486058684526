html {
  .webkit-scrollbar();
  background: #3d4045;
}

body {
  max-width: 1920px;
  overflow-x: hidden;
  margin-left: auto;
  margin-right: auto;
}

.container {
  @media (min-width: 1200px) {
    width: 970px;
  }
  @media (min-width: 1280px) {
    width: 1270px;
  }
  @media (min-width: 1920px) {
    width: 1850px;
  }
}

.sticky {
  position: sticky;
}

.cover-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.tile {
  position: relative;
  &.hover,
  &:hover {
    .edit {
      opacity: 1;
      pointer-events: auto;
    }
    .img-wrap {
      .date {
        background-color: @red;
      }
    }
    .tile-data {
      .icon {
        color: @red;
      }
    }
  }
}

.common-page-body-wrap {
  @media (min-width: 1920px) {
    padding-left: 65px;
    padding-right: 65px;
  }
}

.aside-title {

  font-size: 18px;
  @media (min-width: 768px) {
    font-size: 22px;
  }
  .text {
    display: inline-block;
    font-weight: bold;
    vertical-align: middle;
  }
  &::before {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    margin-right: 15px;
    border: 0.30em solid @red;
    content: "";
    vertical-align: middle;
  }
}

.aside,
.main-content {
  box-sizing: border-box;
}

.aside {
  position: fixed;
  left: 100%;
  top: 0;
  height: 100%;
  z-index: 750;
  width: 100vw;
  @media (min-width: 768px) {
    width: 50vw;
  }
  @media (min-width: 992px) {
    position: sticky;
    z-index: auto;
    left: auto;
    top: 84px;
    height: initial;
    padding-left: 15px;
    padding-right: 15px;
    width: 210px;
    background: none;
  }
}

.main-content {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  @media (min-width: 992px) {
    width: calc(~"100% - 210px");
  }
}

.aside-layout {
  margin-left: -15px;
  margin-right: -15px;
  position: relative;
  @media (min-width: 992px) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

}
