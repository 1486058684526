.main-text {
  color: inherit;
  font-weight: 300;
  line-height: 1.142857142857143;
  margin-top: 0;
  strong {
    font-weight: 800;
  }
  &::after {
    display: inline-block;
    content: "";
    width: 0.8em;
    height: 0.8em;
    border: .15em solid @red;
    vertical-align: baseline;
    margin-left: 15px;
    @media (min-width: 992px) {
      width: 45px;
      height: 45px;
      margin-left: 30px;
      border: 9px solid @red;
    }
  }
  font-size: 22px;
  @media (min-width: 768px) {
    font-size: 40px;
  }
  @media (min-width: 992px) {
    font-size: 48px;
  }
  @media (min-width: 1280px) {
    font-size: 70px;
  }
}

.secondary-text {
  color: inherit;
  font-weight: 300;
  line-height: 1.142857142857143;
  margin-top: 0;
  strong {
    font-weight: 800;
  }
  &::after {
    display: inline-block;
    content: "";
    width: 0.8em;
    height: 0.8em;
    border: .15em solid @red;
    vertical-align: baseline;
    margin-left: 15px;
    @media (min-width: 992px) {
      width: 45px;
      height: 45px;
      margin-left: 30px;
      border: 9px solid @red;
    }
  }
  font-size: 20px;
  @media (min-width: 768px) {
    font-size: 36px;
  }
  @media (min-width: 992px) {
    font-size: 42px;
  }
  @media (min-width: 1280px) {
    font-size: 50px;
  }
}


.main-slider-block {
  background-image: url(../img/bg/main_slider_bg.jpg);
  background-size: cover;
  background-position: center;
  overflow: hidden;
  color: white;
}

.intersection-block {
  background: #21242a;
  color: white;
  padding-top: 25px;
  padding-bottom: 25px;
  line-height: 1.2;
  font-weight: 300;
  font-size: 12px;
  @media (min-width: 768px) {
    font-size: 24px;
    padding-top: 55px;
    padding-bottom: 55px;
  }
  @media (min-width: 992px) {
    font-size: 36px;
  }
  .left-text {
    padding-left: 35px;
    position: relative;
    @media (min-width: 768px) {
      padding-left: 99px;
    }
    @media (min-width: 1280px) {
      padding-left: 160px;
    }
    @media (min-width: 1920px) {
      margin-left: 65px;
    }

    &::before {
      display: block;
      content: "";
      background: @red;
      position: absolute;
      left: -15px;
      top: 50%;
      width: 35px;
      height: 35px;
      margin-top: -17.5px;
      @media (min-width: 768px) {
        width: 70px;
        height: 70px;
        margin-top: -35px;
        left: 0;
      }

    }
    &::after {
      display: block;
      content: "";
      border-bottom: 1px solid white;
      border-right: 1px solid white;
      width: 10px;
      height: 10px;
      left: 0;
      @media (min-width: 768px) {
        width: 20px;
        height: 20px;
        left: 30px;
      }
      position: absolute;
      top: 50%;

      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
  .right-text {
    .big {
      font-size: 1.666666666666667em;
      line-height: 1;
      white-space: nowrap;
      strong {
        font-weight: bold;

      }
    }
    .small {
      font-size: .5em;
      @media (min-width: 1280px) {
        white-space: nowrap;
      }
    }
  }
  .flex-row {
    max-width: 1100px;
  }
}

.solutions-list {
  padding-left: 8px;
  padding-right: 8px;

  @media (min-width: 1280px){
    padding-left: 15px;
    padding-right: 15px;
  }

  .link-colored(white, none);
  .img-wrap {
    display: none;
  }
  a {
    display: block;
    height: 100%;
    text-transform: uppercase;
    background: transparent;
    transition: background .25s, color .25s;
    margin-left: -15px;
    margin-right: -15px;

    &::before {
      content: "";
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }
    span {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      &::before {
        display: block;
        content: "";
        position: absolute;
        top: 100%;

        left: 0;
        width: 75%;
        border-bottom: 2px solid transparent;
        transition: border .25s;
        margin-top: 5px;
        @media (min-width: 1280px) {
          margin-top: 10px;
        }
      }
    }
    &:hover {
      background: white;
      color: @red;
      span {
        &::before {
          border-color: @red;
        }
      }
    }
  }
  .flex-item {
    transition: transform .25s, z-index .25s, box-shadow .25s;
    position: relative;
    z-index: 0;
    box-shadow: 0 0 0 fade(#0e1115, 68%);

    &:hover {
      transform: scale(1.05);
      z-index: 25;
      box-shadow: 0 14px 57px fade(#0e1115, 68%);
    }

  }
  @media (max-width: 767px){
    .flex-item {
      width: 100%;
      background: fade(#191c21, 70%);
      margin-bottom: 5px;
      &:nth-of-type(2n) {
        background: fade(#191c21, 50%);
      }
    }
    a {
      padding: 12px 15px 10px;
      font-size: 10px;

    }
  }
  @media (min-width: 768px) and (max-width: 991px){
    .flex-item {
      width: 33.33333333%;
      background: fade(#191c21, 70%);
      margin-bottom: 35px;
      &:nth-of-type(2n) {
        background: fade(#191c21, 50%);
      }

    }
    a {
      padding: 15px;
      font-size: 16px;

    }
  }
  @media (min-width: 992px) and (max-width: 1279px){
    .flex-item {
      width: 33.33333333%;
      background: fade(#191c21, 70%);
      margin-bottom: 35px;
      &:nth-of-type(2n) {
        background: fade(#191c21, 50%);
      }

    }
    a {
      padding: 15px 30px;
      font-size: 16px;

    }
  }
  @media (min-width: 1280px) and (max-width: 1919px){
    .flex-item {
      width: 25%;
      background: fade(#191c21, 70%);
      margin-bottom: 35px;
      &:nth-of-type(1),
      &:nth-of-type(3n),
      &:nth-of-type(8n) {
        background: fade(#191c21, 50%);
      }

    }
    a {
      padding: 30px;
      font-size: 16px;

    }
  }
  @media (min-width: 1920px) {
    padding-top: 195px;
    .img-wrap {
      display: block;
      position: absolute;
      bottom: 100%;
      left: 0;
      right: 0;
      opacity: 0;
      pointer-events: none;
      transition: opacity.25s;
    }
    a {
      padding: 30px 50px;
      font-size: 18px;
      &:hover {
        + .img-wrap {
          opacity: 1;
        }
      }
    }
    .flex-item {
      position: relative;
      width: 20%;
      background: fade(#191c21, 70%);
      margin-bottom: 35px;
      &:nth-of-type(2n - 1),
      &:nth-of-type(4n - 1),
      &:nth-of-type(6n - 1) {
        background: fade(#191c21, 50%);
      }
    }

  }
}

.solutions-block {
  background-image: url(../img/bg/solutions_bg.jpg);
  background-size: cover;
  background-position: center;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
  @media (min-width: 768px) {
    padding-top: 70px;
    padding-bottom: 20px;
  }
  .main-text-wrap {
    max-width: 1250px;
    margin-bottom: 10px;
    @media (min-width: 768px) {
      margin-bottom: 50px;
    }

    @media (min-width: 1920px) {
      padding-left: 65px;
      max-width: 1310px;
    }
    .main-text {
      @media (max-width: 767px) {
        font-size: 15px;
        margin-bottom: 5px;
      }
    }
  }
  .description {
    font-size: 10px;
    font-weight: 300;
    @media (min-width: 768px) {
      font-size: 18px;
    }
  }
}

.sphere-solutions-list {

  @media (min-width: 768px) {
    padding-left: 15px;
    padding-right: 15px;
  }

  .link-colored(@black_text, none);

  a {
    display: block;
    height: 100%;
    text-align: center;
    background: transparent;
    transition: background .25s, color .25s;
    margin-left: -15px;
    margin-right: -15px;
    font-weight: bold;
    line-height: 1.1;
    text-transform: uppercase;
    @media (min-width: 768px) {
      text-transform: none;
    }
    .icon {
      display: block;
      margin-left: auto;
      margin-right: auto;
      font-size: 42px;
      width: 2em;
      height: 1em;
      color: #838890;
      transition: color .25s;
      margin-bottom: 10px;
      @media (min-width: 768px) {
        font-size: 57px;
      }
      @media (min-width: 1280px) {
        font-size: 75px;
      }
    }
    &:hover {
      background: @red;
      color: white;
      .icon {
        color: white
      }
    }
  }
  .flex-item {
    transition: transform .25s, z-index .25s, box-shadow .25s;
    position: relative;
    z-index: 0;
    box-shadow: 0 0 0 fade(#4f0407, 67%);

    &:hover {
      transform: scale(1.05);
      z-index: 25;
      box-shadow: 0 22px 24px fade(#4f0407, 67%);
    }

  }
  @media (max-width: 767px){
    .flex-item {
      position: relative;
      width: 33.33333333%;
      background: white;
      &:nth-of-type(odd) {
        background: #e5e7ea;
      }
    }
    a {
      padding: 12px 10px 10px;
      font-size: 9px;

    }
  }
  @media (min-width: 768px) and (max-width: 991px){
    .flex-item {
      position: relative;
      width: 33.33333333%;
      background: white;
      &:nth-of-type(odd) {
        background: #e5e7ea;
      }
    }
    a {
      padding: 40px 10px 15px;
      font-size: 18px;

    }
  }
  @media (min-width: 992px) and (max-width: 1279px){
    .flex-item {
      position: relative;
      width: 16.66666666666667%;
      background: white;
      &:nth-of-type(odd) {
        background: #e5e7ea;
      }
    }
    a {
      padding: 30px 10px 15px;
      font-size: 18px;

    }
  }
  @media (min-width: 1280px) and (max-width: 1919px){
    .flex-item {
      position: relative;
      width: 33.33333333%;
      background: white;
      &:nth-of-type(odd) {
        background: #e5e7ea;
      }
    }
    a {
      padding: 40px 10px 15px;
      font-size: 18px;

    }
  }
  @media (min-width: 1920px) {
    a {
      padding: 40px 40px 15px;
      font-size: 18px;
    }
    .flex-item {
      position: relative;
      width: 33.33333333%;
      background: white;
      &:nth-of-type(odd) {
        background: #e5e7ea;
      }
    }

  }
}

.sphere-solutions-block {
  background-image: url(../img/bg/sphere_solutions_bg.jpg);
  background-size: cover;
  background-position: center;
  padding-top: 20px;
  padding-bottom: 20px;
  @media (min-width: 768px) {
    padding-top: 30px;
    padding-bottom: 40px;
  }
  @media (min-width: 992px) {
    padding-top: 100px;
    padding-bottom: 80px;
  }
  color: white;
  .left-block-wrap {
    @media (min-width: 1920px) {
      padding-left: 65px;
      padding-right: 115px;
    }
  }
  .right-block-wrap {
    position: relative;
    box-shadow: 0 31px 62px #0e1115;
    margin-bottom: 35px;
    height: 390px;
    @media (min-width: 1280px) {
      height: 622px;
    }
    @media (min-width: 1920px) {
      height: 630px;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    .text-block {
      position: absolute;
      left: 90px;
      bottom: 70px;
      width: 290px;
      min-height: 290px;
      background: @red;
      padding: 35px 30px 80px;
      font-size: 14px ;
      font-weight: 300;
      &::after {
        display: block;
        content: "";
        width: 26px;
        height: 26px;
        border: 6px solid white;
        position: absolute;
        right: 20px;
        bottom: 25px;
      }
    }
  }
  .main-text {
    margin-bottom: 10px;
    @media (min-width: 992px) and (max-width: 1279px){
      font-size: 46px;
    }
    @media (min-width: 1280px) and (max-width: 1919px){
      font-size: 66px;
    }
    &::after {
      border-color: white;
    }
  }
  .description {
    margin-bottom: 15px;
    font-size: 10px;
    font-weight: 300;
    line-height: 1.2;
    @media (min-width: 768px) {
      font-size: 26px;
      margin-bottom: 43px;
    }
    strong {
      font-weight: bold;
    }
  }
}

.directions-list {
  .flex-item {
    height: 180px;
    width: 100%;
    margin-bottom: 15px;
    .link-colored(white, none);
    @media (min-width: 768px) {
      margin-bottom: 30px;
    }

    .text-box {
      position: absolute;
      left: 0;
      right: 0;
      top: 45px;
      bottom: 45px;
      overflow: hidden;
      transition: all .25s;
      .title {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 45%;
        padding: 0 20px;

        line-height: 1;
        background: fade(#1b1e22, 85%);
        z-index: 1;
        transition: all .25s;
        font-size: 16px;
        @media (min-width: 992px) {
          width: 33%;
        }
        @media (min-width: 1280px) {
          font-size: 22px;
        }
        &::before {
          display: inline-block;
          content: "";
          vertical-align: middle;
          height: 100%;
        }
        span.text {
          display: inline-block;
          vertical-align: middle;
        }

      }
      .description {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;

        padding: 0 50px 0 15px;

        line-height: 1.1;
        background: fade(#1b1e22, 85%);
        transform: translateX(-100%);
        opacity: 0;
        transition: all .25s;
        font-size: 12px;
        width: 55%;
        display: none;
        @media (min-width: 768px) {
          display: block;
        }
        @media (min-width: 992px) {
          width: 67%;
          font-size: 14px;
        }
        @media (min-width: 1280px) {
          font-size: 16px;
        }
        span.line {
          position: absolute;
          top: 30px;
          bottom: 30px;
          left: 0;
          border-right: 1px solid white;
          @media (min-width: 992px) {
            top: 10px;
            bottom: 10px;
          }
        }
        &::before {
          display: inline-block;
          content: "";
          vertical-align: middle;
          height: 100%;
        }
        span.text {
          display: inline-block;
          vertical-align: middle;
        }
        &::after {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 6.5px 0 6.5px 11.3px;
          border-color: transparent transparent transparent white;
          position: absolute;
          right: 16px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    a {
      display: block;
      width: 100%;
      height: 100%;
      position: relative;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
      &:hover,
      .hover {
        .text-box {
          top: 45px;
          bottom: 45px;
          @media (min-width: 768px) {
            top: 25px;
            bottom: 25px;
          }
          @media (min-width: 992px) {
            top: 45px;
            bottom: 45px;
          }
          .title {
            background: @red;
          }
          .description {
            opacity: 1;
            background: @red;
            transform: translateX(0);
          }
        }
      }
    }
  }
  @media (min-width: 768px) {
    .flex-item {
      width: 50%;
    }
  }
  @media (min-width: 1920px) {
    .flex-item {
      width: 33.3333333%;
    }
  }
}

.directions-block {
  color: @black_text;
  padding-top: 20px;
  padding-bottom: 10px;
  @media (min-width: 768px) {
    padding-top: 50px;
    padding-bottom: 40px;
  }
  .main-text-wrap {
    margin-bottom: 20px;
    @media (min-width: 768px) {
      margin-bottom: 50px;
    }
    @media (min-width: 1920px) {
      padding-left: 65px;
      max-width: 1300px;
    }

  }
}

.calculation-block {
  background: #f8f8f8;
  padding-top: 40px;
  padding-bottom: 30px;
  @media (min-width: 768px) {
    background-image: url(../img/bg/calculation_bg.jpg);
    background-size: cover;
    background-position: 70% center;
    padding-bottom: 110px;
  }
  @media (min-width: 992px) {
    background-position: 55% center;

  }
  @media (min-width: 1280px) {
    background-position: center;
  }

  .main-text-wrap {
    @media (min-width: 1280px) {
      max-width: 980px;

    }
    @media (min-width: 1920px) {
      padding-left: 65px;
      max-width: 1200px;
    }
  }
  .jq-selectbox {
    display: block;
  }
  .main-text {
    margin-bottom: 20px;
    font-size: 18px;
    @media (min-width: 768px) {
      max-width: 60%;
      font-size: 40px;
    }
    @media (min-width: 992px) {
      max-width: 750px;
      font-size: 48px;
      margin-bottom: 70px;
    }
    @media (min-width: 1280px) {
      font-size: 48px;
      max-width: initial;
    }
    @media (min-width: 1920px) {
      font-size: 70px;
    }
  }
  .promo-text {
    @media (min-width: 768px) {
     max-width: 60%;
    }
    @media (min-width: 992px) {
      padding-top: 125px;
      max-width: initial;
    }
    p {
      font-size: 24px;
      font-weight: 300;
      line-height: 1.2;
      margin-bottom: 1.2em;
    }
    li {
      font-size: 20px;
    }
  }
}
