.about-top {
  padding-top: 40px;
  padding-bottom: 10px;
  font-size: 10px;
  background: url(../img/bg/header_bar_bg.jpg);
  background-position: center top;
  background-size: cover;
  font-weight: 300;
  p strong {
    font-weight: bold;
  }
  @media (min-width: 768px) {
    padding-top: 50px;
    padding-bottom: 40px;
    font-size: 18px;
  }
  .main-text-wrap {
    margin-bottom: 20px;
    @media (min-width: 768px) {
      margin-bottom: 50px;
    }
    @media (min-width: 1920px) {
      padding-left: 65px;
      max-width: 1300px;
    }

  }
}

.merit-tile {
  .top-box {
    background: #f3f3f3;
    padding: 1.666666666666667em 1.666666666666667em 3.333333333333333em;
    font-size: 1em;
    margin-bottom: 1.666666666666667em;
    text-align: center;
    @media (min-width: 768px) {
      text-align: left;
    }
    .icon {
      font-size: 6em;
      @media (min-width: 768px) {
        font-size: 4.444444444444444em;
      }
    }
    position: relative;
    &::after {
      display: block;
      content: "";
      border-bottom: 6px solid @red;
      position: absolute;
      bottom: 1.666666666666667em;
      left: 0;
      width: 66.6666667%;
    }

  }
  .title {
    font-weight: 900;
  }
}

.merits-slider {

  .slide {
    padding-left: 7px;
    padding-right: 7px;
    @media (min-width: 768px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .slick-arrow {

    background: #394249;

    padding: 11px;
    margin-top: -30px;
    @media (min-width: 768px) {
      padding: 11px;

    }

    &.slick-prev {

      margin-right: -30px;

      @media screen and (min-width: 768px) {
        margin-right: -30px;
      }

    }
    &.slick-next {

      margin-left: -30px;

      @media screen and (min-width: 768px) {
        margin-left: -30px;
      }

    }

  }
}

.about-merits {
  padding-top: 20px;
  padding-bottom: 10px;
  font-size: 10px;

  @media (min-width: 768px) {
    padding-top: 50px;
    padding-bottom: 40px;
    font-size: 14px;
  }
  @media (min-width: 992px) {
    font-size: 10px;
  }
  @media (min-width: 1280px) {
    font-size: 14px;
  }
  @media (min-width: 1920px) {
    font-size: 18px;
  }

  .main-text-wrap {

    @media (min-width: 1920px) {
      padding-left: 65px;
      padding-right: 65px;
    }

  }

  .main-text {
    padding-right: 10%;
    @media (min-width: 768px) {
      padding-right: 0;
    }
  }
}

.video-block {

  position: relative;
  background: black;
  .video-sizer {
    padding-bottom: 56.25%;
  }
  .video-wrapper {
    max-width: 1690px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //z-index: 10;
  }
  .preview {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: opacity 1s;
    .main-text {
      max-width: 1100px;
      position: relative;
      z-index: 2;
      color: white;

      @media (max-width: 767px) {
        font-size: 15px;
      }
    }
    .main-text-wrap {
      padding-top: 10px;
      @media (min-width: 768px) {
        padding-top: 40px;
      }
      @media (min-width: 1280px) {
        padding-top: 85px;
      }
      @media (min-width: 1920px) {
        padding-left: 65px;
        padding-right: 65px;
      }

    }
    .review-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      img {
        width: 74px;
        @media (min-width: 768px) {
          width: 146.5px;
        }
        @media (min-width: 1280px) {
          width: 293px;
        }
      }

    }
  }
  &.show {
    .preview {
      opacity: 0;
      pointer-events: none;
    }
  }

}

.about-video {

}

.about-history {
  padding-top: 20px;
  padding-bottom: 10px;
  font-size: 10px;

  @media (min-width: 768px) {
    padding-top: 50px;
    padding-bottom: 40px;
    font-size: 18px;
  }

  .main-text-wrap {

    @media (min-width: 1920px) {
      padding-left: 65px;
      padding-right: 65px;
    }

  }
}

.time-row {
  font-size: 10px;
  @media (min-width: 768px) {
    font-size: 10px;
  }
  @media (min-width: 992px) {
    font-size: 12px;
  }
  @media (min-width: 1280px) {
    font-size: 16px;
  }
  .year {
    display: inline-block;
    font-size: 1.875em;
    font-weight: 300;
    position: relative;
    background: white;
    padding-left: 10px;
    padding-right: 10px;
  }
  .text {
    font-size: 1em;
    line-height: 1.2;
    max-width: 90%;
    text-align: left;
  }
  hr {
    border-width: 4px;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  > .flex-item {
    width: calc(~"100% / 3 ");
    @media (min-width: 768px) {
      width: calc(~"100% / 8 * 6");
    }

    flex-grow: 1;
    flex-shrink: 1000;
    hr {
      border-color: #6595b1;
    }
    &:not(:last-child) {
      .year {
        position: relative;
        &::after {
          white-space: nowrap;
          display: block;
          content: '• • • • • • • •';
          position: absolute;
          color: #c9c9c9;
          left: 100%;
          top: 0;
          //margin-left: 0.8333333333333333em;
          width: 3.333333333333333em;
        }
        @media (min-width: 1920px) {
          &::after {
            margin-left: 0.8333333333333333em;
          }
        }
      }
    }
    &:first-child,
    &:last-child {
      width: calc(~"100% / 3 ");
      @media (min-width: 768px) {
        width: calc(~"100% / 8 + 30px");
      }

      flex-grow: 1000;
      flex-shrink: 1;
    }
    &:first-child {
      hr {
        border-color: #c9c9c9;
      }
      .year {
        margin-left: -10px;
        @media (min-width: 1920px) {
          &::after {
            margin-left: 0.8em;
          }
        }
      }
    }
    &:last-child {
      text-align: right;
      .year {
        margin-right: -10px;
      }
      hr {
        border-color: #c10f28;
      }
    }

  }
  .year-row {
    > .flex-item {
      width: 100%;
      .year {
        margin-left: -7px;
        transform: translateX(-50%);
      }
      &:last-child {
        width: 0;
        .year {
          margin-left: 7px;
          transform: translateX(-50%);
        }
      }
      &:first-child {

      }
    }
  }
}

.history-tile {
  font-size: 10px;
  @media (min-width: 768px) {
    font-size: 12px;

  }
  @media (min-width: 992px) {
    font-size: 18px;

  }
  @media (min-width: 1280px) {
    font-size: 22px;
  }
  .img-wrap {
    margin-bottom: 1.363636363636364em;
  }
  @media (min-width: 768px) {
    position: relative;
    padding-left: 11.36363636363636em;
    min-height: 9.545454545454545em;;
    .img-wrap {
      position: absolute;
      top: 0;
      left: 0;
      max-width: 9.545454545454545em;
    }
    .text-wrap {
      padding-left: 1.818181818181818em;
      position: relative;
      &::before {
        display: block;
        content: "";
        height: 3.863636363636364em;
        border-left: 1px solid @red;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  .text-wrap {

    .year {
      font-size: 2.181818181818182em;
      font-weight: 900;
      margin-bottom: 0.2083333333333333em;
      line-height: 1;
    }
    .title {
      font-size: 1em;
      margin: 0 0 0.4545454545454545em;
    }
    .description {
      font-size: 1em;
      .contracts {
        font-size: 0.7272727272727273em;
        color: #898989;
      }
    }
  }
}

.history-slider {
  @media (min-width: 768px) {
    margin-left: -15px;
    margin-right: -15px;
  }

  .slide {
    padding-left: 7px;
    padding-right: 7px;
    @media (min-width: 768px) {
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 30px;
    }
  }
  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    .slide {

    }
  }
  @media (min-width: 768px) {
   .slide {
      width: 50%;
    }
  }
  @media (min-width: 1920px) {
     .slide {
      width: 33.3333333333%;
    }
  }
  .slick-arrow {

    background: #394249;

    padding: 11px;
    margin-top: -55px;
    @media (min-width: 768px) {
      padding: 11px;

    }

    &.slick-prev {

      margin-right: -30px;

      @media screen and (min-width: 768px) {
        margin-right: -30px;
      }

    }
    &.slick-next {

      margin-left: -30px;

      @media screen and (min-width: 768px) {
        margin-left: -30px;
      }

    }

  }
}

.about-mission {
  background: #d8d9d3;

  .left-block-wrap {
    padding-top: 20px;

    @media (min-width: 768px) {
      padding-top: 30px;

    }
    @media (min-width: 1280px) {
      padding-top: 100px;

    }
    @media (min-width: 1920px) {
      padding-left: 65px;
      padding-right: 115px;
    }
  }
  .right-block-wrap {

  }
  .main-text {
    margin-bottom: 10px;
    @media (min-width: 992px) and (max-width: 1279px) {
      font-size: 46px;
    }
    @media (min-width: 1280px) and (max-width: 1919px) {
      font-size: 66px;
    }

  }
  .description {
    margin-bottom: 15px;
    font-size: 10px;
    font-weight: 300;
    line-height: 1.2;
    @media (min-width: 768px) {
      font-size: 26px;
      margin-bottom: 43px;
    }
    strong {
      font-weight: bold;
    }
  }
  .more-text {
    font-size: 10px;
    @media (min-width: 768px) {
      font-size: 14px;

    }
  }
}

.about-synenergy {
  padding-top: 20px;

  background-color: #21242a;
  background-image: url(../img/bg/synenergy_bg_small.jpg);
  background-size: 100% auto;
  background-position: center bottom;
  background-repeat: no-repeat;
  padding-bottom: 70vw;
  @media (min-width: 992px) {
    background-image: url(../img/bg/synenergy_bg_big.jpg);
    background-size: cover;
    background-position: center;
    padding-bottom: 0;
  }

  font-size: 10px;
  font-weight: 300;

  @media (min-width: 768px) {
    padding-top: 50px;

    font-size: 18px;
  }
  @media (min-width: 992px) {
    min-height: 740px;
    font-size: 14px;
  }
  @media (min-width: 1280px) {
    min-height: 1000px;
    font-size: 18px;
  }
  @media (min-width: 1920px) {
    min-height: 1064px;
  }
  .more-text {
    font-size: 10px;
    @media (min-width: 768px) {
      font-size: 14px;

    }
  }
  .main-text {
    padding-right: 10%;
    @media (min-width: 768px) {
      padding-right: 0;
    }
  }
  .main-text-wrap {
    @media (min-width: 992px) {
      max-width: 600px;
      .more-text {
        max-width: 380px;
      }
    }
    @media (min-width: 1280px) {
      max-width: 730px;
      .more-text {
        max-width: 500px;
      }
    }
    @media (min-width: 1920px) {
      padding-left: 65px;
      padding-right: 65px;
      .more-text {
        max-width: 100%;
      }
    }

  }
  .abbr-list {
    border-spacing: 0 0.8888888888888889em;
    border-collapse: separate;
    tr {
      padding-top: 0.2777777777777778em;
      padding-bottom: 0.2777777777777778em;

    }
    td {
      &:first-child {
        padding-right: 1.277777777777778em;
        text-align: right;
        border-right: 1px solid fade(white, 50%);
      }
      &:last-child {
        padding-left: 1.277777777777778em;
      }
    }
  }
}
