.range-slider {
  width: 100%;
  background: #dcdcdc;
  height: 5px;
  position: relative;

  .ui-slider-range {
    background: @red;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .ui-slider-handle {
    position: absolute;
    top: 0;
    cursor: grab;
    height: 100%;
    &:focus {
      outline: none;

    }
    &.ui-state-active {
      cursor: grabbing;
    }
    &::after {
      display: block;
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 6.5px 11.3px 6.5px;
      border-color: transparent transparent @red transparent;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 4px;
    }
    .handle-label {
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
      font-size: 14px;
      font-weight: bold;
    }
  }
}

.range-slider-wrap {
  position: relative;
  padding-left: 135px;
  padding-top: 30px;
  padding-bottom: 30px;
  .label {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
    max-width: 100px;
    line-height: 1.2;
  }
}
