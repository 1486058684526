.slider {
  .slide {
    display: block !important;
    .img-wrap {
      display: inline-block;
    }
    ~ .slide {
      display: none !important;
    }
  }
}

.slick-slide {
  &:focus,
  a {
    outline: none !important;
  }

}

.slick-track {
  margin-left: 0;
}

.slick-arrow {
  position: absolute;
  top: 50%;
  z-index: 3;
  transform: translateY(-50%);
  background: transparent;
  transition: background .25s;
  border: none;
  padding: 11px;
  @media (min-width: 768px) {
    padding: 21px;
  }
  span {
    display: block;
    width: 22px;
    height: 22px;
    border-top: 1px solid white;
    transition: border .25s;
  }

  &.slick-prev {
    right: 100%;
    margin-right: -50px;
    span {
      border-left: 1px solid white;
      transform: translateX(5px) rotate(-45deg);
    }

    @media screen and (min-width: 768px) {
      margin-right: 0px;
    }

    @media screen and (min-width: 1920px) {
      margin-right: -60px;
    }
  }
  &.slick-next {
    left: 100%;
    margin-left: -50px;
    span {
      border-right: 1px solid white;
      transform: translateX(-5px) rotate(45deg);
    }
    @media screen and (min-width: 768px) {
      margin-left: 0px;
    }

    @media screen and (min-width: 1920px) {
      margin-left: -60px;
    }
  }
  &:focus {
    outline: none !important;
  }
  &:hover {
    //background: fade(@red, 75%);
  }

}

.slick-dots {

  button {
    display: block;
    font-size: 0;
    //width: 15px;
    //height: 15px;
    //background: @dark;
    border: none;
  }
  .slick-active {
    button {
      //background: @red;
    }
  }
  li {
    display: inline-block;
    &:not(:last-of-type) {
      margin-right: 15px;
    }

  }
}

.news-page-slider {
  margin-left: -15px;
  margin-right: -15px;
  .slide {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.news-block-slider {
  position: relative;
  .slide {
    padding-bottom: 20px;
    @media (min-width: 1200px) {
      padding-bottom: 0;
    }
  }

  .slick-dots {
    position: absolute;
    bottom: 10px;
    left: 15px;
    @media (min-width: 1200px) {
      left: 30px;
    }
    z-index: 10;
  }
}

.action-slider {
  position: relative;
  .slick-dots {
    position: absolute;
    bottom: 40px;
    right: 15px;
    z-index: 10;
    @media (min-width: 480px) {
      bottom: 10px;
    }
    button {
      background: white;
      //box-shadow: 0 0 2px black;
    }
    .slick-active {
      button {
        background: @red;
      }
    }
  }
}

.article-arrow {
  background: #72747a;
  width: 34px;
  height: 34px;
  padding: 11px;
  @media (min-width: 768px) {
    padding: 11px;
  }
  span {
    width: 12px;
    height: 12px;
  }
  &.slick-prev {
    margin-right: -30px;
    @media screen and (min-width: 768px) {
      margin-right: -30px;
    }
    @media screen and (min-width: 1920px) {
      margin-right: -30px;
    }
    span {
      transform: translateX(3px) rotate(-45deg);
    }

  }
  &.slick-next {
    margin-left: -30px;
    @media screen and (min-width: 768px) {
      margin-left: -30px;
    }
    @media screen and (min-width: 1920px) {
      margin-left: -30px;
    }
    span {
      transform: translateX(-3px) rotate(45deg);

    }
  }
}


.article-slider {
  padding-left: 40px;
  padding-right: 40px;
}
