.search-filter {
  padding: 0 15px;
  fieldset {
    padding: 0;
    border: none;
    margin: 0;
  }
  legend {
    //border: none;
    font-size: 16px;
    max-width: 300px;
  }
  label {
    display: inline-block;
    font-weight: normal;
    font-size: 16px;
    margin-right: 15px;
    @media (min-width: 992px) {
      display: block;
      margin-right: 0;
    }

    input:checked + span {
      font-weight: bold;
      color: @red;
    }
  }
}

.search-block .aside-layout,
.search-block .search-input-wrap {
  @media (min-width: 1600px) {
    max-width: 1200px;
  }
}

.search-block .xl-wrap {
  @media (min-width: 1920px) {
    padding-right: 70px;
    padding-left: 70px;
  }
}

.search-tile {

  font-size: 14px;
  position: relative;
  @media (min-width: 768px) {

    padding-left: 160px;
    padding-right: 160px;
    min-height: 130px;
  }
  .img-wrap {
    @media (min-width: 768px) {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .date {

    position: absolute;
    top: 0;
    right: 0;

    background: #b8b8b8;
    transition: background .25s;
    color: white;
    padding: 3px 10px;

    font-size: 8px;
    @media (min-width: 768px) {
      padding: 10px 20px;
      font-size: 10px;
    }
    span.big {
      font-size: 2.4em;
      font-weight: bold;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
    }
    span.small {
      font-size: 1em;
      line-height: 1.1;
      display: inline-block;
      vertical-align: middle;
    }
  }
  &.hover,
  &:hover {
    .date {
      background-color: @red;
    }
  }
  .label {
    text-transform: uppercase;
    font-size: 1.142857142857143em;
    color: #b2b2b2;
  }
  .h3 {
    font-size: 1.142857142857143em;
    font-weight: 400;
  }
  .title {
    text-transform: uppercase;
  }
  &:hover {
    .comments,
    .likes {
      .icon {
        color: @red;
      }

    }
  }
}

.search-input-wrap {
  position: relative;
  .reset-btn {
    position: absolute;
    top: 50%;
    right: 10px;
    font-size: 30px;
    transform: translateY(-50%);
    margin-top: .05em;
  }
  .form-control {
    background: transparent;
    border: none;
    border-bottom: 1px solid @black_text;
    box-shadow: none;
    font-size: 20px;
    padding-left: 0;
    padding-right: 30px;
  }
}
