

.techno-slider {
  .slick-arrow {
    &.slick-prev {
      margin-right: -50px;
      @media screen and (min-width: 768px) {
        margin-right: -64px;
      }
      @media screen and (min-width: 1920px) {
        margin-right: -60px;
      }
    }
    &.slick-next {
      margin-left: -50px;
      @media screen and (min-width: 768px) {
        margin-left: -64px;
      }
      @media screen and (min-width: 1920px) {
        margin-left: -60px;
      }
    }
  }
  .slide {
    position: relative;
    .container {
      @media (max-width: 767px) {
        width: 100%;
      }
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .text-wrap {
      @media (min-width: 992px) {
        padding-right: 300px;
        padding-left: 20px;
      }
      @media (min-width: 1280px) {
        padding-right: 200px;
        padding-left: 0;

      }
      @media (min-width: 1920px) {
        padding-right: 720px;
        padding-left: 65px;
      }

    }
    .main-text {
      font-size: 16px;
      margin-bottom: 1em;
      @media (min-width: 768px) {
        font-size: 40px;
      }
      @media (min-width: 992px) {
        font-size: 26px;
      }
      @media (min-width: 1280px) {
        font-size: 55px;
      }
      &::after {
        @media (min-width: 992px) {
          width: 0.8em;
          height: 0.8em;
          border: .15em solid @red;
        }
        @media (min-width: 1280px) {
          width: 45px;
          height: 45px;
          margin-left: 30px;
          border: 9px solid @red;
        }
      }
    }
    .description {
      max-width: 540px;
      font-size: 11px;
      font-weight: 300;
      @media (min-width: 768px) {
        font-size: 18px;
        max-width: 400px;
      }
      @media (min-width: 992px) {
        font-size: 14px;
        max-width: 400px;
      }
      @media (min-width: 1280px) {
        font-size: 18px;
        max-width: 540px;
      }
    }
  }
}

.techno-slider-dots {

  position: absolute;
  right: 0;
  width: calc(~"100vw - 30px");
  display: flex;
  bottom: 5px;

  @media (min-width: 768px) {
    width: 360px;
    bottom: 30px;
    margin-right: 30px;
  }
  @media (min-width: 992px) {
    width: 470px;
    margin-right: 65px;
  }
  @media (min-width: 1280px) {
    width: 630px;

  }
  @media (min-width: 1920px) {
    bottom: 40px;
  }
  li {
    width: 70px;

    padding-top: 3px;
    transition: all 1s;

    &:not(:last-of-type) {
      margin-right: 10px;
      @media (min-width: 768px) {
        margin-right: 20px;
      }

    }
    button {
      display: block;
      width: 100%;
      height: 2px;
      opacity: .25;
      background: @red;
      transition: all 1s;
    }
  }
  .slick-active {
    padding-top: 0;
    button {
      opacity: 1;
      height: 5px;

    }
  }
}

.techno-text-block {
  color: @black_text;
  padding-top: 20px;
  padding-bottom: 10px;
  font-size: 11px;
  font-weight: 300;
  @media (min-width: 768px) {
    padding-top: 50px;
    padding-bottom: 40px;
    font-size: 18px;
  }
  .main-text-wrap {
    margin-bottom: 20px;
    @media (min-width: 768px) {
      margin-bottom: 50px;
    }
    @media (min-width: 1920px) {
      padding-left: 65px;
      max-width: 1300px;
    }

  }
}

.techno-red-ribbon {
  padding-top: 15px;
  padding-bottom: 15px;
  background: @red;
  color: white;
  @media (min-width: 768px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .main-text-wrap {
    @media (min-width: 1920px) {
      padding-left: 65px;
      padding-right: 65px;
    }
  }
  .slogan {
    font-size: 11px;
    white-space: nowrap;
    display: flex;
    align-items: flex-start;
    @media (min-width: 768px) {
      font-size: 22px;
      align-items: center;
    }
    @media (min-width: 992px) {
      font-size: 18px;
    }
    @media (min-width: 1280px) {
      font-size: 22px;
    }
    > * {
      display: inline-block;
      vertical-align: middle;
    }
    .icon {
      flex-grow: 1000;
      font-size: 3.181818181818182em;
      margin-right: 0.7142857142857143em;

    }
    .text {
      flex-shrink: 1000;
      > * {
        display: inline;
        white-space: normal;
      }
      .title {
        font-weight: bold;
        font-size: 1em;
        display: block;
        margin: 0 0 .5em;
        @media (min-width: 768px) {
          margin: 0;
          display: inline;
          padding-right: 4.545454545454545em;
          position: relative;
          &::after {
            display: block;
            content: "";
            position: absolute;
            width: 1.363636363636364em;
            right: 1.590909090909091em;
            top: 50%;
            border-bottom: 1px solid currentColor;
          }
        }

      }
    }
  }
}

.techno-solutions-block {
  .main-text-wrap {
    @media (min-width: 1920px) {
      max-width: 50%;
    }
  }
}

.techno-services-tile {
  background: #1c1d1e;
  color: white;
  height: 100%;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  font-size: 8px;
  font-weight: 300;
  position: relative;
  transform: scale(1) translateZ(0);
  margin-left: -15px;
  margin-right: -15px;
  @media (min-width: 768px) {
    font-size: 18px;
    margin-left: 0;
    margin-right: 0;
    padding-left: 3.888888888888889em;
    padding-right: 5em;
    padding-bottom: 4.444444444444444em;
  }
  @media (min-width: 1280px) {
    font-size: 14px;
  }
  @media (min-width: 1920px) {
    font-size: 18px;
  }
  .flex-row {
    flex-direction: row-reverse;
    align-items: flex-start;
    @media (min-width: 768px) {
      flex-direction: row;

      align-items: center;
    }

  }
  .title {
    text-transform: uppercase;
    font-size: 2.222222222222222em;
    font-weight: 900;
    margin-top: 0;
    padding-top: .5em;
    margin-bottom: 1em;
    border-top: .4em solid @red;
  }
  .flex-item {
    .img-wrap {
      width: 150px;
      @media (min-width: 768px) {
        width: 130px;
      }
      @media (min-width: 992px) {
        width: 280px;
      }
      @media (min-width: 1280px) {
        width: 130px;
      }
      @media (min-width: 1920px) {
        width: 280px;
      }
    }
    &:first-child {
      flex-grow: 1000;
      flex-shrink: 1;

    }
    &:last-child {
      flex-grow: 1;
      flex-shrink: 1000;
    }
  }
  .text-wrap {

    position: relative;
    font-size: 1.25em;
    padding-top: 1em;
    padding-bottom: 2.5em;
    margin-right: -20px;
    @media (min-width: 768px) {
      padding-top: 0;
      padding-bottom: 0;
      font-size: 1em;
      padding-left: 40px;
      margin-left: 15px;
      margin-right: 0;
    }
    @media (min-width: 1280px) {
      padding-left: 30px;
      margin-left: 0px;
    }
    @media (min-width: 1920px) {
      padding-left: 40px;
      margin-left: 15px;
    }
    &::before {
      @media (min-width: 768px) {
        display: block;
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        border-left: 1px solid @red;
        height: 150px;
        margin-top: -75px;
      }
      @media (min-width: 992px) {
        height: 200px;
        margin-top: -100px;
      }
      @media (min-width: 1280px) {
        height: 150px;
        margin-top: -75px;
      }
      @media (min-width: 1920px) {
        height: 200px;
        margin-top: -100px;
      }
    }
  }
  .link-wrap {
    position: absolute;
    left: 2.222222222222222em;
    bottom: 1.111111111111111em;
    @media (min-width: 768px) {
      left: auto;
      right: 2.222222222222222em;
    }
  }

  .common-btn {

    display: block;
    width: 3.333333333333333em;
    height: 3.333333333333333em;
    background: @red;
    border-color: @red;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0.8888888888888889em;
      height: 0.8888888888888889em;
      margin-top: -0.4444444444444444em;
      margin-left: -0.6666666666666667em;
      border-top: 1px solid white;
      border-right: 1px solid white;
      transform: rotate(45deg);
    }
  }
}

.techno-services-row {
  > * {
    @media (max-width: 767px) {
      margin-bottom: 15px!important;
    }
  }
}

.techno-services-block {
  padding-top: 30px;
  padding-bottom: 30px;
  color: @black_text;
  .main-text-wrap {
    @media (min-width: 1920px) {
      padding-left: 65px;
      padding-right: 65px;
    }
  }

}

.techno-news-tile {
  display: block;
  position: relative;

  transform: scale(1) translateZ(0);
  .img-wrap {
    background: #5d5d5d;
    display: block;
    img {
      opacity: .3;
      transition: transform .25s;
    }
  }
  .content-wrap {
    padding: 10px 0 0;
    font-size: 11px;
    display: block;

    @media (min-width: 768px) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 120px 30px 30px;
      font-size: 18px;
    }
    @media (min-width: 1280px) {
      font-size: 14px;
      padding: 80px 30px 30px;
    }
    @media (min-width: 1920px) {
      font-size: 18px;
      padding: 120px 30px 30px;
    }
    .date {
      display: block;
      font-weight: 300;
      font-size: 1em;
      margin-bottom: 1.222222222222222em;
      text-shadow: 0 0 1px black, 0 0 5px black;
      @media (max-width: 767px) {
        position: absolute;
        top: 5.666666666666667em;
        left: 1.666666666666667em;
      }

    }
    .title {
      display: block;
      margin: 0;
      font-size: 1.222222222222222em;
      line-height: 1.363636363636364em;
      @media (max-width: 767px) {
        color: @black_text;
      }
      @media (min-width: 768px) {
        text-shadow: 0 0 1px black, 0 0 5px black;
      }
      span {
        border-bottom: 1px solid fade(white, 50%);
        transition: border .25s;
      }
      &:hover {
        span {
          border-color: transparent;
        }
      }
    }
    .label {
      position: absolute;
      left: 0;
      top: 2em;
      background: #161616;
      padding: 0.5555555555555556em 1.666666666666667em;
      font-weight: 300;

      &.red {
        background: @red;
      }
    }
  }
  &:hover {
    .img-wrap {
      img {
        transform: scale(1.4);
        transition: transform 2.5s;
      }
    }
  }
}

.techno-news-row {
  width: calc(~"100% + 14px");
  margin-left: -7px;
  margin-right: -7px;
  > * {
    padding-left: 7px;
    padding-right: 7px;
  }
  @media (min-width: 768px) {
    width: calc(~"100% + 30px");
    margin-left: -15px;
    margin-right: -15px;
    > * {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.techno-news-block {
  padding-top: 30px;
  padding-bottom: 30px;
  color: @black_text;
  .main-text-wrap {
    @media (min-width: 1920px) {
      padding-left: 65px;
      padding-right: 65px;

    }
  }

  .link-colored(white, none);
}

.techno-fos-block {
  padding-top: 30px;
  padding-bottom: 30px;
  color: @black_text;
  background: #f0f2f5;
  .main-text {
    font-size: 22px;
    @media (max-width: 767px) {
      margin-bottom: 20px!important;
    }
    @media (min-width: 768px ) {
      font-size: 40px;
    }
    @media (min-width: 992px) {
      font-size: 48px;
      margin-right: -100px;
    }
    @media (min-width: 1280px) {
      font-size: 50px;
      margin-right: 0;
    }
    @media (min-width: 1920px) {
      font-size: 70px;
    }
  }
  .main-text-wrap {
    @media (min-width: 1920px) {
      padding-left: 65px;
      padding-right: 65px;

    }
  }
  form {
    @media (min-width: 1280px) {
      padding-right: 30px;
    }
  }
  .img-wrap {
    position: relative;
    margin-left: -15px;
    margin-bottom: -30px;
    @media (min-width: 1280px) {
      margin-left: -30px;
    }
    .techno-man-text {
      position: absolute;
      right: 0;
      top: 50%;
      width: 190px;
      text-shadow: 0 0 1px white, 0 0 2px white;
      font-size: 16px;
      @media (min-width: 992px) {
        left: 60px;
        top: 30px;
        font-size: 14px;
      }
      @media (min-width: 1280px) {
        font-size: 16px;
      }
      @media (min-width: 1920px) {
        left: auto;
        right: 0;
        top: 50%;
      }
      .name {
        font-size: 1.25em;
        font-weight: normal;
      }
      .post {
        font-weight: bold;
      }
      hr {
        border-color: @red;
        width: 85px;
        margin-left: 0;
      }
    }
  }
}

.techno-vacancies-block {
  padding-top: 30px;
  padding-bottom: 30px;
  color: @black_text;

  .main-text-wrap {
    @media (min-width: 1920px) {
      padding-left: 65px;
      padding-right: 65px;

    }
  }

  .vacancies-tile {
    background: #f6f6f6;
    padding: 30px;
  }
}

.techno-contacts-block {
  padding-top: 30px;

  color: @black_text;
  .main-text-wrap {
    @media (min-width: 1920px) {
      padding-left: 65px;
      padding-right: 65px;

    }
  }
  .phone-email {
    @media (min-width: 768px) {
      text-align: right;

    }
    font-size: 16px;
    .email {
      .link-colored(@red, none);
      font-size: 1em;
      font-weight: 300;
      a {
        span {
          border-bottom: 1px solid fade(@red, 50%);
        }
        &:hover {
          span {
            border-color: transparent;
          }
        }
      }


    }
    .phone {
      .link-colored(@black_text, none);
      font-size: 1.875em;
      font-weight: 900;
    }
  }
}

.mini-map-address {
  position: relative;
  padding-left: 60px;
  font-size: 16px;
  margin-bottom: 0;
  padding-bottom: 20px;
  .icon {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 2.875em;
    color: @red;
  }
  .city {
    font-weight: bold;
    font-size: 1.25em;
  }
  &::after {
    display: block;
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 260px;
    border-bottom: 6px solid @red;
  }
}
