.contacts-page {
  background-image: url(../img/bg/contacts_bg.jpg);
  background-size: cover;
  background-position: center top;
  padding-top: 30px;
  padding-bottom: 30px;
  .xl-wrap {
    @media (min-width: 1920px) {
      padding-left: 70px;
      padding-right: 70px;
    }
  }
}

.contacts-row {
  > * {
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
    @media (min-width: 768px) {
      margin-right: 30px;
    }
    @media (min-width: 992px) {
      margin-right: 50px;
    }
  }
  .marker {

  }
  .address {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.2;
    margin-bottom: 0;
    @media (min-width: 768px) {
      font-size: 24px;
    }
    .ttu {
      font-size: 1.25em;
      font-weight: bold;
    }
  }
  .line {

  }
  .phone-email {
    position: relative;
    padding-left: 70px;

    @media (min-width: 768px) {
      padding-left: 60px;
      &::before {
        display: block;
        content: "";
        border-left: 1px solid fade(white, 50%);
        transform: rotate(30deg);
        height: 80px;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -40px;
      }
    }
    .link-colored(white, none);
    > * {
      display: block;

    }
    font-size: 18px;
    font-weight: 300;
    line-height: 1.2;
    @media (min-width: 768px) {
      font-size: 24px;
    }
    .phone {
      font-size: 1.25em;
      font-weight: bold;
    }
    .email {
      .text {
        border-bottom: 1px solid fade(white, 50%);
        transition: all .25s;
        line-height: 1;
        display: inline-block;
      }
      &:hover {
        .text {
          border-color: transparent;
        }
      }

    }
  }
}

.form-wrap {
  background: fade(#23262b, 63%);
  padding-top: 40px;
  padding-bottom: 30px;

}

.contacts-form {
  max-width: 925px;
}

.manager-tile {

  @media (min-width: 768px) {
    position: relative;
    padding-left: 180px;
  }
  @media (min-width: 768px) {
    padding-left: 135px;
  }
  @media (min-width: 1280px) {
    padding-left: 180px;
  }
  @media (min-width: 1920px) {
    padding-left: 250px;
  }

  .img-wrap {

    @media (min-width: 768px) {
      width: 120px;
      position: absolute;
      top: 0;
      left: 0;

    }
    @media (min-width: 1280px) {
      width: 150px;
    }
    @media (min-width: 1920px) {
      width: 210px;
    }
  }
  .content {


    @media (min-width: 768px) {
      height: 120px;
      padding-left: 15px;
      border-left: 1px solid white;

    }
    @media (min-width: 1280px) {
      height: 150px;
      padding-left: 30px;
    }
    @media (min-width: 1920px) {
      height: 210px;
    }
    .name {
      margin: 0;
      font-size: 20px;
      font-weight: bold;
      @media (min-width: 768px) {
        font-size: 16px;
      }
      @media (min-width: 1280px) {
        font-size: 20px;
      }
      @media (min-width: 1920px) {
        font-size: 24px;
      }
    }
    .post {
      font-size: 14px;
      font-weight: normal;
      @media (min-width: 768px) {
        font-size: 12px;
      }
      @media (min-width: 1280px) {
        font-size: 14px;
      }
      @media (min-width: 1920px) {
        font-size: 18px;
      }
    }
    .link-colored(white, none);
    .phone {
      display: block;
      .text {
        font-size: 14px;
        font-weight: bold;
        @media (min-width: 768px) {

        }
        @media (min-width: 1280px) {
          font-size: 14px;
        }
        @media (min-width: 1920px) {
          font-size: 18px;
        }
      }
    }
    .email {
      display: block;
      .text {
        font-size: 14px;
        font-weight: 300;
        @media (min-width: 768px) {

        }
        @media (min-width: 1280px) {
          font-size: 14px;
        }
        @media (min-width: 1920px) {
          font-size: 18px;
        }
      }
    }
  }
}

.manager-slider {
  margin-left: -15px;
  margin-right: -15px;
  .slide {
    padding-left: 15px;
    padding-right: 15px;
  }
}
