.vacancies-tile {
  margin-bottom: 30px;
  position: relative;

  .link-colored(@black_text, none);

  span.metro {
    display: inline-block;
    vertical-align: 0;
    margin-right: 5px;
    width: 24px;
    height: 24px;
    background: @red;
    color: white;
    border-radius: 50%;
    line-height: 26px;
    text-align: center;
    margin-top: 5px;
  }

  .title,
  .salary {
    font-size: 18px;
    font-weight: bold;
    margin: 0 0 5px;
  }

  .title {
    padding-right: 30px;

    span {
      transition: all .25s;
      display: inline-block;
      border-bottom: 1px solid fade(@black_text, 25%);
    }
    a:hover {
      span {
        border-color: transparent!important;
      }
    }
  }

  .description {
    //font-size: 14px;
    //font-weight: 300;
    margin-bottom: 5px;
  }

  .city {
    //font-size: 14px;
  }

  .date {
    color: #9a9a9a;
  }
  .favorite-checker {
    position: absolute;
    top: 25px;
    right: 30px;
  }
}

.vacancies-tile-wrap {
  background: #f6f6f6;
  padding: 15px;
  margin-bottom: 15px;
  transition: all .25s;
  &:hover {
    box-shadow: 0 15px 25px fade(black, 25%);
  }
  @media (min-width: 768px) {
    padding: 30px;
    margin-bottom: 30px;
  }
}

.favorite-checker {
  svg {
    font-size: 24px;
  }
  svg:nth-of-type(1) {
    color: #9a9a9a;
  }
  svg:nth-of-type(2) {
    color: @red;
    display: none;
  }
  input:checked ~ svg:nth-of-type(1) {
    display: none;
  }
  input:checked ~ svg:nth-of-type(2) {
    display: block;
  }
}

