
.main-slider {
  .slide {
    display: flex!important;
    align-items: stretch;
  }
  .left-side,
  .right-side {
    @media (min-width: 768px) {
      //width: 50%;
      //float: left;
    }
  }
  .left-side {

    padding-top: 60px;
    width: 100%;
    @media (min-width: 768px) {
      width: 50%;
    }
    @media (min-width: 1920px) {
      width: 960px;
      padding-left: 65px;
    }

    .line-btn-wrap {
      margin-bottom: 30px;
    }
  }
  .right-side {
    position: relative;
    padding-top: 170px;
    width: 0;
    @media (min-width: 768px) {
      width: 50%;
    }
    @media (min-width: 992px) {
      padding-top: 50px;
    }
    @media (min-width: 1920px) {
      width: calc(~"100% - 960px");
    }
    .line-btn-wrap {
      position: absolute;
      right: 100%;
      width: 360px;
      bottom: 100px;
      @media (min-width: 992px) {
        left: 140px;
        right: 0;
        width: initial;
        bottom: 115px;
      }
      @media (min-width: 1280px) {
        left: 260px;
      }
      @media (min-width: 1920px) {
        left: 0;
      }



      animation-delay: .25s;
      animation-duration: .5s;
      &::before {
        display: block;
        content: "";
        border-bottom: 1px solid @red;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
      }
    }
    .img-wrap {

      //display: block;
      animation-delay: .75s;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      @media (min-width: 992px) {
        padding-left: 60px;

      }
      @media (min-width: 1280px) {
        padding-left: 140px;

      }
      @media (min-width: 1920px) {
        padding-left: 60px;

      }
      img {
        @media (min-width: 922px) {
          margin-bottom: -65px;
        }
        @media (min-width: 1280px) {
          margin-bottom: -65px;
        }
        @media (min-width: 1920px) {
          margin-bottom: -75px;
        }
      }
    }
  }
  .main-text {
    position: relative;
    z-index: 10;
    animation-duration: .5s;
    margin-bottom: 10px;
    @media (min-width: 768px) {
      margin-right: -160px;
      margin-bottom: 40px;
    }
    @media (min-width: 1280px) {
      margin-right: -280px;
    }
    @media (min-width: 1920px) {
      margin-right: 0;
    }
  }
  .description {
    animation-duration: .5s;
    animation-delay: .25s;
    color: white;

    font-weight: 300;
    line-height: 1.333333333333333;
    max-width: 630px;
    font-size: 10px;
    margin-bottom: 15px;
    @media (min-width: 768px) {
      font-size: 18px;
      margin-bottom: 45px;
    }
    @media (min-width: 1280px) {
      margin-bottom: 45px;
      font-size: 24px;
    }
    @media (min-width: 1920px) {
      margin-bottom: 75px;
    }
  }
  .white-more-btn {
    animation-duration: .5s;
    animation-delay: .5s;
    margin-bottom: 30px;
  }
}



.main-slider-dots {


  position: absolute;
  left: 0;
  width: calc(~"100vw - 30px");
  display: flex;
  bottom: 5px;

  @media (min-width: 768px) {
    width: 360px;
    bottom: 30px;
  }
  @media (min-width: 992px) {
    width: 470px;
  }
  @media (min-width: 1280px) {
    width: 630px;
  }
  @media (min-width: 1920px) {
    margin-left: 65px;
    bottom: 40px;
  }
  li {
    width: 70px;

    padding-top: 3px;
    transition: all 1s;

    &:not(:last-of-type) {
      margin-right: 10px;
      @media (min-width: 768px) {
        margin-right: 20px;
      }

    }
    button {
      display: block;
      width: 100%;
      height: 2px;
      opacity: .25;
      background: white;
      transition: all 1s;
    }
  }
  .slick-active {
    padding-top: 0;
    button {
      opacity: 1;
      height: 5px;

    }
  }
}

