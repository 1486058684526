.date-selector-wrap {
  .jq-selectbox:not(:last-child) {
    margin-right: 15px;
  }
  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
  .jq-selectbox__dropdown {
    top: 100% !important;
  }
  @media (min-width: 992px) {
    white-space: nowrap;
  }
}

.news-btns-wrap {
  &.text-right {
    @media (max-width: 767px) {
      text-align: left;
    }
  }
  .jq-file:not(:last-child) {
    margin-right: 15px;
    @media (max-width: 480px) {
      margin-bottom: 10px;
    }
  }
  .common-btn {
    vertical-align: -1px;
  }
  .giant-btn:not(:last-of-type) {
    margin-right: 15px;
    @media (max-width: 480px) {
      margin-bottom: 10px;
    }
  }
  &.docs-btns-wrap {
    .jq-file {
      border-color: @red;
      background: #f5f5f5;
      .jq-file__name {
        color: @red;
      }

    }
  }
}

.mfp-content {
  @media (max-width: 767px) {
    max-height: 100vh;
    max-width: 100vw;
    overflow: auto;
  }
}

.mfp-image-holder {
  .mfp-content {
    max-width: 85%;
    @media (max-width: 767px) {
      max-height: initial;
      max-width: 85%;
      overflow: initial;
    }
  }
}

.mfp-gallery {
  button.mfp-close {
    top: 20px;
    right: -20px;
  }

}

.popup {
  position: relative;
  max-width: 992px;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
  max-height: 95vh;
  overflow-y: auto;
  @media (min-width: 768px) {
    padding: 30px;
  }
  fieldset {
    padding: 0;
    border: none;
    legend {
      border: none;
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
  &.popup-medium {
    max-width: 810px;
  }
  &.popup-small {
    max-width: 550px;
  }
  .title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
    @media (min-width: 768px) {
      font-size: 24px;
    }
  }
  &.public-popup {
    max-width: 805px;
    border-top: 8px solid @red;
    background: @aside_bg;
    padding: 55px 120px 45px;
    .form-control {
      background: white;
      box-shadow: none;
      &:not(:focus) {
        border-color: white;
      }
    }
    .jq-selectbox__select {
      background: white;
    }
    .jq-selectbox:not(.opened) {
      .jq-selectbox__select {
        border-color: white;
      }
    }
    .decorated-title {
      margin-left: -75px;
    }
    label {
      display: block;
      font-weight: 300;
      font-size: 16px;
      position: relative;
      padding-left: 35px;
      .jq-checkbox {
        position: absolute;
        top: -3px;
        left: 0;
      }
    }
    small {
      font-size: 16px;
      font-weight: normal;
      display: block;
      line-height: 1.2;
    }
    fieldset {
      @media (min-width: 768px) {
        position: relative;
        text-align: right;
        legend {
          display: block;
          width: initial;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    .date-selector-wrap .jq-selectbox:not(:last-child) {
      margin-right: 5px;
      @media (min-width: 768px) {
        margin-right: 15px;
      }
    }
    .jq-selectbox__select {
      padding: 0 30px 0 10px;

      @media (min-width: 768px) {
        padding: 0 55px 0 30px;
      }

    }
    @media (max-width: 767px) {
      padding: 30px 15px 20px;
      .mfp-close {
        background: transparent;
      }

      .decorated-title {
        margin-bottom: 10px;
        &::before {
          display: none;
        }
      }

      small {
        font-size: 14px;
      }

    }
    @media (min-width: 480px) and (max-width: 767px) {
      .captcha-wrap,
      input[name='captcha'] {
        max-width: 275px;
      }
    }
  }

}

.success-popup {
  p {
    font-size: 23px;
  }
}

.popup-notification {
  background: @red;
  color: white;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  padding: 35px;
  @media (min-width: 768px) {
    padding: 65px;
  }
  .mfp-close {
    display: none;
  }

}

.route-selector {
  .jq-selectbox {
    width: 100%;
  }
}

.task-block {
  height: auto;
  min-height: 100%;
  @media (min-width: 768px) {
    height: auto;
  }
  .label {
    color: @red;
    margin-bottom: 10px;
  }
  .value {
    font-size: 110%;

  }
}

.date-block {
  display: none;
}

.task-comments {
  ul {
    margin-left: 10px;
  }
  ul:not(.list-unstyled):not(.list-inline) li {
    padding-left: 20px;
    margin-bottom: 10px;
  }
}

.files-wrap {
  position: relative;
  padding-right: 250px;
  margin-top: 20px;
  min-height: 60px;
  [type='checkbox'] {
    display: none;
    ~ * {
      display: none;
    }
    &:checked {
      ~ * {
        display: inline-block;
      }
    }
  }
  .files-list {
    margin: 0;
    li {
      display: flex;
      max-width: 500px;

      .filename {
        font-size: 14px;
        color: #595959;
        text-decoration: underline;
        margin-bottom: 10px;
      }
      label {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 2px;
        margin-bottom: 10px;
      }
    }
  }
  .add-file-btn {
    position: absolute;
    top: 0;
    right: 0;
    background: white;
    border: 2px solid @red;
    white-space: nowrap;
    .text {
      color: @red;
      text-transform: none;
      display: inline-block;
      vertical-align: middle;
    }
    .icon {
      color: @red;
      font-size: 150%;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
    }
  }
}

.client-file-list {
  .jq-file {

    margin-bottom: 10px;
    background: none;
    padding: 0;
    border: none;
    &:hover {
      box-shadow: none;
    }
    &:active {
      top: 0;
    }
    > * {
      font-size: 14px;
      color: #595959;
      text-decoration: underline;
      text-transform: none;
    }
  }
  .label {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    margin-bottom: 10px;
  }
}
