/*=====================
  Базовая типография
=====================*/

@font-face {
  font-family: 'Circe';
  //src: url('../font/Circe-ExtraBold.eot');
  src: url('../font/Circe-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('../font/Circe-ExtraBold.woff2') format('woff2'),
  url('../font/Circe-ExtraBold.woff') format('woff'),
  url('../font/Circe-ExtraBold.ttf') format('truetype'),
  url('../font/Circe-ExtraBold.svg#Circe-ExtraBold') format('svg');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circe';
  //src: url('../font/Circe-Bold.eot');
  src: url('../font/Circe-Bold.eot?#iefix') format('embedded-opentype'),
  url('../font/Circe-Bold.woff2') format('woff2'),
  url('../font/Circe-Bold.woff') format('woff'),
  url('../font/Circe-Bold.ttf') format('truetype'),
  url('../font/Circe-Bold.svg#Circe-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circe';
  //src: url('../font/Circe-Light.eot');
  src: url('../font/Circe-Light.eot?#iefix') format('embedded-opentype'),
  url('../font/Circe-Light.woff2') format('woff2'),
  url('../font/Circe-Light.woff') format('woff'),
  url('../font/Circe-Light.ttf') format('truetype'),
  url('../font/Circe-Light.svg#Circe-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circe';
  //src: url('../font/Circe-ExtraLight.eot');
  src: url('../font/Circe-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('../font/Circe-ExtraLight.woff2') format('woff2'),
  url('../font/Circe-ExtraLight.woff') format('woff'),
  url('../font/Circe-ExtraLight.ttf') format('truetype'),
  url('../font/Circe-ExtraLight.svg#Circe-ExtraLight') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circe';
  //src: url('../font/Circe-Thin.eot');
  src: url('../font/Circe-Thin.eot?#iefix') format('embedded-opentype'),
  url('../font/Circe-Thin.woff2') format('woff2'),
  url('../font/Circe-Thin.woff') format('woff'),
  url('../font/Circe-Thin.ttf') format('truetype'),
  url('../font/Circe-Thin.svg#Circe-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circe';
  //src: url('../font/Circe-Regular.eot');
  src: url('../font/Circe-Regular.eot?#iefix') format('embedded-opentype'),
  url('../font/Circe-Regular.woff2') format('woff2'),
  url('../font/Circe-Regular.woff') format('woff'),
  url('../font/Circe-Regular.ttf') format('truetype'),
  url('../font/Circe-Regular.svg#Circe-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@base-font: Circe, Arial, sans-serif;

body {
  font-family: @base-font;
  font-size: 14px;
  line-height: 1.42857143;
  color: @black_text;
  * {
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

}

button,
input,
optgroup,
select,
textarea {
  font-family: @base-font;
}

a {
  color: @red;
  text-decoration: underline;
  &:hover {
    color: darken(@red, 5%);
    text-decoration: none;
  }
  &:focus {
    color: @red;
    text-decoration: underline;
  }
  &:active,
  &:active:focus {
    color: darken(@red, 10%);
    text-decoration: none;
  }
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit
}

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small, .h1 small, .h2 small, .h3 small, .h4 small, .h5 small, .h6 small, h1 .small, h2 .small, h3 .small, h4 .small, h5 .small, h6 .small, .h1 .small, .h2 .small, .h3 .small, .h4 .small, .h5 .small, .h6 .small {
  font-weight: normal;
  line-height: 1;
  color: #777
}

h1, .h1, h2, .h2, h3, .h3 {
  margin-top: 20px;
  margin-bottom: 10px
}

h1 small, .h1 small, h2 small, .h2 small, h3 small, .h3 small, h1 .small, .h1 .small, h2 .small, .h2 .small, h3 .small, .h3 .small {
  font-size: 65%
}

h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 10px;
  margin-bottom: 10px
}

h4 small, .h4 small, h5 small, .h5 small, h6 small, .h6 small, h4 .small, .h4 .small, h5 .small, .h5 .small, h6 .small, .h6 .small {
  font-size: 75%
}

h1, .h1 {
  font-size: 36px
}

h2, .h2 {
  font-size: 30px
}

h3, .h3 {
  font-size: 24px
}

h4, .h4 {
  font-size: 18px
}

h5, .h5 {
  font-size: 14px
}

h6, .h6 {
  font-size: 12px
}

p {
  margin: 0 0 10px
}

/********************************************/

.ttu {
  text-transform: uppercase;
}

.h1 {
  font-weight: bold;
  text-transform: uppercase;
}

.decorated-title {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 73px;
  padding-top: .15em;
  position: relative;
  font-size: 20px;
  min-height: 45px;
  @media (min-width: 768px) {
    font-size: 36px;
  }
  &::before {
    display: block;
    content: "";
    position: absolute;
    box-sizing: content-box;
    left: 0;
    top: 0;
    width: 25px;
    height: 25px;
    border: 10px solid @red;
  }
  span {
    font-weight: 300;
  }
}

.underlined-title {
  font-size: 28px;
  text-transform: uppercase;
  font-weight: 900;
  position: relative;
  border-bottom: 1px solid @red;
  padding-bottom: 10px;
  margin-top: 0;
  &::after {
    display: block;
    content: "";
    width: 60px;
    height: 5px;
    background: @red;
    position: absolute;
    left: 0;
    bottom: -3px;
  }
}


blockquote {
  border: 1px solid @red;
  position: relative;
  padding: 20px 30px 20px 48px;
  font-size: 16px;
  margin-left: 22px;
  @media (min-width: 768px) {
    padding: 40px 70px;
  }
  &::before {
    display: block;
    content: "";
    position: absolute;
    box-sizing: content-box;
    left: -22px;
    top: 50%;
    transform: translateY(-50%);
    width: 25px;
    height: 25px;
    border: 10px solid @red;
    background: white;
  }
  strong {

    line-height: 1.2;
    font-size: 16px;
    @media (min-width: 768px) {
      font-size: 30px;
    }
    @media (min-width: 992px) {
      font-size: 36px;
    }
  }
}

.rich-text-content {
  font-size: 18px;
  p {
    margin-bottom: 1.2em;
  }
  h3 {
    text-transform: uppercase;
    font-size: 22px;
    font-weight: bold;
    &:not(:first-child) {
      margin-top: 30px;
    }
  }

  ul:not(.list-unstyled):not(.list-inline) {

    li {
      &::before {
        border-radius: 50%;
      }
    }
  }
  &.vacancy-mod {
    p {
      margin-bottom: .7em;
    }
  }

}

article {
  h3.ttu {
    margin-top: 40px;
    font-size: 14px;
    @media (min-width: 768px) {
      font-size: 22px;
    }
  }
}
