.services-page {
  padding-top: 50px;
  padding-bottom: 50px;
  background: url(../img/bg/services_bg.jpg);
  background-position: center top;
  background-size: cover;
  @media (min-width: 768px) {
    padding-top: 30px;
    padding-bottom: 100px;
  }
  .main-text-wrap {
    @media (min-width: 1920px) {
      padding-left: 70px;
      padding-right: 70px;

    }

    font-weight: 200;
    transform: scale(1) translateZ(0);
    font-size: 16px;
    @media (min-width: 768px) {
      font-size: 20px;
      padding-top: 20px;

    }

    p {
      line-height: 1.2;
      margin-bottom: 2em;
    }
  }
  .sphere-solutions-block {
    background: none;
    padding: 0;
  }
}

.tags-row {
  margin: 0;
  font-size: 1.5em;
  line-height: .6;
  color: #b8b8b8;
  transition: all .25s;
}


.service-tile {
  transform: scale(1) translateZ(0);
  background: #f6f6f6;

  color: @black_text;
  height: 100%;


  line-height: 1.2;
  font-weight: 300;
  position: relative;
  cursor: pointer;
  transition: all .25s;
  font-size: 14px;
  padding: 15px;
  @media (min-width: 768px) {
    font-size: 20px;
    padding: 30px;
  }
  @media (min-width: 992px) {
    font-size: 14px;
  }
  @media (min-width: 1280px) {
    font-size: 20px;
    min-height: 405px;
  }
  .flex-item:first-child {
    flex-shrink: 1;
    flex-grow: 1000;
  }
  .flex-item:last-child {
    flex-shrink: 1000;
    flex-grow: 1;
  }
  .flex-row {
    flex-wrap: wrap;
    @media (min-width: 768px) {
      flex-wrap: nowrap;
    }
  }
  .img-wrap {
    text-align: left;
    margin-bottom: 20px;
    @media (min-width: 768px) {
      margin-bottom: 0;
    }
  }

  .title {
    text-transform: uppercase;
    font-size: 1.2em;
    line-height: 1;
    font-weight: normal;
    padding-left: 15px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-left: 2px solid @red;
    margin: 0 0 1em;
    transition: border .25s;
  }
  .text-wrap {
    padding-bottom: 65px;
  }
  .tags-row {
    position: absolute;
    bottom: 30px;
    right: 30px;
  }
  .service-more-btn-wrap {
    position: absolute;
    bottom: 30px;
    right: 30px;
    opacity: 0;
    pointer-events: none;
    transition: all .25s;
  }

  &:hover {
    background: @red;
    color: white;
    .tags-row {
      color: @red;
      pointer-events: none;
    }
    .service-more-btn-wrap {
      opacity: 1;
      pointer-events: auto;
    }
    .title {
      border-color: white;
    }
  }

}

.services-grid {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: -15px;
  margin-right: -15px;
  width: calc(~"100% + 30px");
  .grid-item {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 15px;
    @media (min-width: 768px) {
      margin-bottom: 30px;
    }
  }

  @media (min-width: 992px) {
    .big-item {
      width: 100%;
    }

    .medium-item {
      width: 50%;
    }

    .small-item {
      width: 50%;
    }
  }

  @media (min-width: 1920px) {
    .big-item {
      width: 66.66666666666667%;
    }

    .medium-item {
      width: 50%;
    }

    .small-item {
      width: 33.33333333333333%;
    }
  }
}

.services-grid-wrap {
  @media (min-width: 1920px) {
    padding-left: 40px;
    padding-right: 40px;
  }
}
