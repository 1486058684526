ul, ol {
  margin-top: 0;
  margin-bottom: 10px
}

ul ul, ol ul, ul ol, ol ol {
  margin-bottom: 0
}

.list-unstyled {
  padding-left: 0;
  list-style: none
}

.list-inline {
  padding-left: 0;
  list-style: none;
  margin-left: -5px
}

.list-inline > li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px
}

ul:not(.list-unstyled):not(.list-inline) {
  padding: 0;
  > li {
    list-style: none;
    padding-left: 2.5em;
    position: relative;
    margin-bottom: 1em;
    &::before {
      display: block;
      content: "";
      width: 0.6875em;
      height: 0.6875em;
      background: @red;
      position: absolute;
      left: 0;
      top: .35em;
    }
  }
}

ul.cheks-list {
  > li {
    padding-left: 45px;
    position: relative;
    margin-bottom: .75em;
    &::before {
      display: block;
      content: "";
      border-left: 2px solid @red;
      border-bottom: 2px solid @red;
      width: 1.15em;
      height: 0.75em;
      transform: rotate(-45deg);
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

ol:not(.list-unstyled):not(.list-inline) {
  padding: 0;
  > li {
    list-style: none;
    padding-left: 2.5em;
    position: relative;
    margin-bottom: 1em;
    counter-increment: inst;
    min-height: 3.075em;
    padding-top: 0.45em;
    &::before {
      display: block;
      content: counter(inst);
      font-size: 0.6666666666666667em;
      font-weight: 900;
      width: 2.75em;
      height: 2.75em;
      line-height: 2.85em;
      text-align: center;
      border-radius: 50%;
      background: @red;
      color: white;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}



