.breadcrumbs {
  color: inherit;
  font-size: 10px;
  @media (min-width: 768px) {
    font-size: 14px;
  }
  .link-colored(@black_text, none);
  a {
    .text {
      border-bottom: 1px solid fade(@black_text, 50%);
    }
    &:hover {
      .text {
        border-color: transparent;
      }
    }
  }
  li {
    margin-bottom: 5px;
  }
  li:not(:last-of-type) {
    position: relative;
    &::after {
      display: inline;
      content: '-';
      position: absolute;
      right: -4px;
      @media (min-width: 768px) {
        right: -5px;
      }
      top: -2px;
      font-size: 1.2em;
      font-weight: 300;
    }
  }
}

.dark-back {
  color: white;
  .breadcrumbs {

    font-weight: 300;
    .link-colored(white, none);
    a {
      .text {
        border-bottom: 1px solid fade(white, 50%);
      }
    }
  }

}
