
.news-tile {
  margin-bottom: 30px;
  background-color: @aside_bg;
  padding: 15px;
  @media (min-width: 1200px) {
    padding: 30px;
  }

  .img-wrap {
    position: relative;
    overflow: visible;
    .date {
      position: absolute;
      top: -15px;
      left: -15px;
      width: 70px;
      height: 70px;
      box-sizing: border-box;
      text-align: center;
      background-color: #b8b8b8;
      transition: background .25s;
      color: white;

      padding: 6px 0;
      @media (min-width: 1200px) {
        left: -30px;
        top: -30px;
      }

      font-size: 10px;
      span.big {
        font-size: 2.4em;
        font-weight: bold;
        display: block;
      }
      span.small {
        font-size: 1em;
        line-height: 1.1;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
  .title {
    font-size: 13px;
    line-height: 1.5;
    margin-top: 1em;
    margin-bottom: .5em;
    font-weight: bold;
    max-height: 3em;
    overflow: hidden;
    @media (min-width: 768px) {
      font-size: 16px;
    }

  }
  .announce {
    max-height: 5.714285714285714em;
    overflow: hidden;
    font-size: 12px;
    @media (min-width: 768px) {
      font-size: 14px;
    }
    @media (min-width: 1200px) {
      max-height: 4.285714285714286em;
    }
  }
  .text-wrap {
    height: 126px;
    @media (min-width: 1200px) {
      padding-right: 50px;
    }
  }

}

.news-page-date {
  background: @red;
  color: white;
  display: inline-block;
  padding: 5px 20px 3px;
}

.small-news-tile {
  .text-wrap {

    height: initial;
    padding-top: 60px;

  }
  .w-image {
    padding-top: 0;
    @media (min-width: 768px) {
      padding-left: 145px;
      height: 130px;
      padding-top: 30px;
    }
    @media (min-width: 1200px) {
      padding-left: 160px;
    }

    .img-wrap {
      @media (min-width: 768px) {
        position: absolute;
        top: 30px;
        left: 15px;
      }
      @media (min-width: 1200px) {
        left: 30px;
      }
    }
    .title {
      margin-top: 0;
      max-height: initial;
      overflow: initial;
      @media (min-width: 768px) {
        font-size: 14px;
      }
      @media (min-width: 1200px) {
        font-size: 16px;
      }
    }
  }
  .date {
    position: absolute;
    top: 0;
    left: 0;
    background: #b8b8b8;
    transition: background .25s;
    color: white;
    padding: 10px 20px;

    font-size: 10px;
    span.big {
      font-size: 2.4em;
      font-weight: bold;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
    }
    span.small {
      font-size: 1em;
      line-height: 1.1;
      display: inline-block;
      vertical-align: middle;
    }
  }
  &.hover,
  &:hover {
    .date {
      background-color: @red;
    }
  }
}

.tile-data {
  font-size: 16px;
  position: relative;
  z-index: 5;
  .icon {
    color: @disable;
    font-size: 1.5em;
    display: inline-block;
    vertical-align: middle;
    margin-right: .3em;
    transition: color .25s;
  }
  .text {
    display: inline-block;
    vertical-align: middle;
    color: @black_text;
    line-height: 1;
    border-bottom: 1px solid fade(@black_text, 50%);
  }
  .comments,
  .likes {
    cursor: pointer;
    white-space: nowrap;
    &:not(:last-child) {
      margin-right: .75em;
      @media (min-width: 768px) {
        margin-right: 1.5em;
      }
    }
  }
}

.tile-social-links {
  .social-icon {
    background-color: @disable;
    position: relative;
    overflow: visible;
    img {
      position: relative;
    }
    &::before {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: transparent;
      transition: all .25s;
    }
    &:hover {
      &::before {
        top: -5px;
        bottom: -15px;
        background-color: @red;
      }
    }
  }
  .social-list {
    position: absolute;
    right: 10px;
    bottom: 15px;
    @media (min-width: 1200px) {
      right: 25px;
    }

  }
}

//.news-list {
//  .flex-item {
//    box-sizing: border-box;
//    width: 100%;
//    @media (min-width: 480px) {
//      width: 50%;
//    }
//  }
//}

.news-list {
  .flex-item {
    @media (min-width: 768px) {
      width: calc(~"100% / 2");
    }
    @media (min-width: 1920px) {
      width: calc(~"100% / 3");
    }
  }
}

.main-news-list {
  .flex-item {
    @media (min-width: 1920px) {
      &.hidden-lg {
        display: block !important;
      }
    }
  }
}

.news-block {
  padding-top: 20px;
  padding-bottom: 30px;
  @media (min-width: 768px) {
    padding-top: 100px;
    padding-bottom: 70px;
  }
  .main-text-wrap:not(.wide-text)  {
    @media (min-width: 768px) {
      max-width: 600px;
    }
    @media (min-width: 1280px) {
      max-width: 980px;
    }
    @media (min-width: 1920px) {
      max-width: 1000px;
    }
  }
  .main-text-wrap {
    @media (min-width: 1920px) {
      padding-left: 65px;

    }
  }
}

.news-page {
  padding-top: 0;
  padding-bottom: 0;
  @media (min-width: 768px) {
    padding-top: 0;
    padding-bottom: 0;
  }
  .aside-title {
    margin-top: 0;
  }
  .header-bar {
    background-image: url(../img/bg/header_bar_bg.jpg);
    background-size: cover;
    background-position: center;
    padding-top: 40px;
    padding-bottom: 30px;
    min-height: 100px;
    @media (min-width: 768px) {
      min-height: 150px;
      padding-top: 20px;
      .breadcrumbs {
        margin-bottom: 30px;
      }
    }
    @media (min-width: 992px) {
      min-height: 200px;
    }
    .main-text{
      font-weight: 900;
    }
  }
}

.date-label {
  display: inline-block;
  background: @red;
  color: white;
  font-weight: 300;
  font-size: 16px;
  padding: 5px 20px 3px;
}

.article-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 30px;
  @media (min-width: 768px) {
    font-size: 36px;
  }
}

.news-article {
  font-size: 14px;
  @media (min-width: 768px) {
    font-size: 18px;
  }
}
