
ymaps[class*="-map-bg"] {
  background: white;
}

.map-block {
  ymaps[class*="-ground-pane"] {
    filter: grayscale(1);
    opacity: .5;

  }
}

ymaps[class*="-balloon"] {
  box-shadow: inset 0 0 0 1px fade(@red, 15%), 0 5px 15px -7px rgba(0, 0, 0, .5);

}

ymaps[class*="-balloon__layout"],
ymaps[class*="-balloon__content"],
ymaps[class*="-balloon_layout_panel"] {
  background: @red;
  box-shadow: none;
}

ymaps[class*="-balloon__content"] {
  padding: 20px;
  color: white;
  font-weight: 300;
  font-size: 14px;
  font-family: @base-font;
  line-height: 1.4;
  span.metro {
    display: inline-block;
    vertical-align: 0;
    margin-right: 5px;
    width: 24px;
    height: 24px;
    padding-right: 1px;
    background: @dark;
    border-radius: 50%;
    line-height: 26px;
    text-align: center;
    margin-top: 5px;
  }
}

ymaps[class*="-balloon-content__header"] {
  text-transform: uppercase;
  font-weight: bold;
  box-shadow: none;
  font-size: 18px;
  margin-bottom: 10px;
  span {
    display: inline-block;
    border-bottom: 1px solid fade(white, 25%);
  }
  .link-colored(white, none);
  a:hover {
    span {
      border-color: transparent;
    }
  }
}

ymaps[class*="-balloon-content__footer"] {
  color: white;
  box-shadow: none;
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
}

ymaps[class*="-balloon__close"],
ymaps[class*="balloon__close-button"] {
  box-shadow: none;
}

ymaps[class*="balloon__close-button"] {
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTQgLjdsLS43LS43TDcgNi4zLjcgMCAwIC43IDYuMyA3IDAgMTMuM2wuNy43TDcgNy43bDYuMyA2LjMuNy0uN0w3LjcgN3oiIGNsaXAtcnVsZT0iZXZlbm9kZCIgc3R5bGU9ImZpbGw6d2hpdGUiLz48L3N2Zz4=) 50% no-repeat;
  opacity: .5;
}

ymaps[class*="-balloon__tail"] {
  box-shadow: none;
  &::after {
    background: #b7080b;
  }
}

@media (max-width: 767px) {
  #map {
    max-height: 75vh;
  }

  .map-block {
    #map {
      max-height: initial;
      height: 490px;
    }
  }
}

.map-container {

  @media (min-width: 768px) {
    position: relative;

  }
  .main-text-wrap {
    position: absolute;
    z-index: 10;
    top: 25px;
    pointer-events: none;
    text-shadow: 0 0 1px white, 0 0 5px white;
    max-width: 300px;
    @media (min-width: 768px) {
      top: 90px;
    }
    @media (min-width: 992px) {
      max-width: 350px;
    }
    @media (min-width: 1920px) {
      padding-left: 65px;
      max-width: 415px;
    }
  }
  .address-wrap {
    font-size: 12px;
    font-weight: 300;
    display: flex;
    flex-direction: row;
    margin-left: -15px;
    margin-right: -15px;
    > * {
      padding-left: 15px;
      padding-right: 15px;
    }
    @media (min-width: 768px) {
      flex-direction: column;
      font-size: 24px;
      margin-left: 0;
      margin-right: 0;
      > * {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .address {
      @media (min-width: 768px) {
        margin-bottom: 30px;
      }
      .ttu {
        font-weight: bold;
        font-size: 1.25em;
      }
    }
    .phone {
      font-weight: bold;
    }
    .email {
      @media (min-width: 768px) {
        margin-bottom: 30px;
      }
      a {

        span {
          border-bottom: 1px solid fade(white, 50%);
          transition: all .25s;
        }
        &:hover {
          span {
            border-color: transparent;
          }
        }
      }
    }
  }
  .form-wrap {
    position: absolute;
    z-index: 10;
    bottom: 0;
    right: 0;
    left: 0;
    background: fade(#101010, 80%);
    color: white;
    padding: 30px;
    @media (min-width: 768px) {
      top: 90px;
      bottom: auto;
      left: auto;
    }
    @media (min-width: 1280px) {
      padding: 50px 70px;
    }
    @media (min-width: 1920px) {
      padding: 50px 130px;
    }
    .link-colored(white, none);
    hr {
      border-color: fade(white, 50%);
    }
    .form-title {
      font-size: 20px;
      font-weight: bold;
      @media (min-width: 768px) {
        width: 320px;
        font-size: 30px;
      }
    }
  }
}

.map-block {
  overflow: hidden;
  position: relative;

}
