.flex-row {
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
  width: calc(~"100% + 30px");
  > * {
    float: none;
  }
  .flex-item {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 100%;

  }
  &.narrow-row {
    margin-left: -7px;
    margin-right: -7px;
    width: calc(~"100% + 14px");
    > * {
      padding-left: 7px;
      padding-right: 7px;
    }
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-lg {
  @media (min-width: 1200px) {
    flex-wrap: wrap;
  }
}

.flex-wrap-md {
  @media (min-width: 992px) and (max-width: 1199px) {
    flex-wrap: wrap;
  }
}

.flex-wrap-sm {
  @media (min-width: 768px) and (max-width: 991px) {
    flex-wrap: wrap;
  }
}

.flex-wrap-xs {
  @media (min-width: 320px) and (max-width: 767px){
    flex-wrap: wrap;
  }
}

.jcc {
  justify-content: center;
}

.jsb {
  justify-content: space-between;
}

.jcs {
  justify-content: flex-start;
}

.aic {
  align-items: center;
}

.ait {
  align-items: flex-start;
}

.aib {
  align-items: flex-end;
}

.ais {
  align-items: stretch;
}


